import React, { Fragment, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import UserGuard from '../shared/auth/UserGuard';
import Home from './pages/Home';
import useScrollToTop from '../shared/hooks/useScrollToTop';

function AuthUser() {
  useScrollToTop();
  return (
    <Fragment>
      <Suspense fallback={<div></div>}>
        <div className="flex-1 mt-16">
          <Switch>
            <UserGuard exact path="/u" component={Home} />
          </Switch>
        </div>
      </Suspense>
    </Fragment>
  );
}

AuthUser.propTypes = {};

export default AuthUser;
