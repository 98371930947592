import { useEffect, useRef, useState } from 'react';

const useLocalStorage = (key, initialValue = '') => {
  const [value, setValue] = useState(() => {
    return localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : typeof initialValue === 'function'
      ? initialValue()
      : initialValue;
  });

  const ref = useRef(key);

  useEffect(() => {
    if (key !== ref.current) {
      localStorage.removeItem(ref.current);
      ref.current = key;
    }

    if (value) localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};

export default useLocalStorage;
