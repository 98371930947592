import React from 'react';
import SearchResult from '../components/SearchResult';

function SearchResults(props) {
  return (
    <div>
      <section>
        <SearchResult />
      </section>
    </div>
  );
}

SearchResults.propTypes = {};

export default SearchResults;
