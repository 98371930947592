import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import Facilities from '../components/Facilities';

class Home extends Component {
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>Mrara Enterprises | Homepage</title>
          <meta name="description" content="#" />
        </MetaTags>
        <section>
          <Facilities />
        </section>
      </Fragment>
    );
  }
}

export default Home;
