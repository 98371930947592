import React from 'react';
// import PropTypes from 'prop-types'
import { MetaTags } from 'react-meta-tags';
import PasswordResetRequestForm from '../components/PasswordResetRequestForm';

function PasswordResetRequest(props) {
  return (
    <div className="min-h-screen">
      <MetaTags>
        <title>Mrara Enterprises | Password Reset Request</title>
        <meta name="description" content="#" />
      </MetaTags>
      <section>
        <PasswordResetRequestForm />
      </section>
    </div>
  );
}

export default PasswordResetRequest;
