import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  verifyEmail,
  verifyPhoneNumber,
} from '../../shared/services/authService';
import { Form, Input, Button } from 'antd';

export default function VerifyAccount() {
  const history = useHistory();

  const [emailStatus, setEmailStatus] = useState('loading');
  const [phoneStatus, setPhoneStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const params = new URLSearchParams(history.location.search);
  const token = params.get('token');

  useEffect(() => {
    if (token) {
      if (emailStatus === 'loading') {
        (async () => {
          let res = await verifyEmail({ token }).catch((err) => {
            setEmailStatus('rejected');
            history.push('/login');
          });
          if (res) setEmailStatus('email_resolved');
        })();
      }
    } else {
      setEmailStatus('error');
      setErrorMessage('Invalid token provided');
    }
    // eslint-disable-next-line
  }, [token]);

  const resendCode = async () => {
    setPhoneStatus('loading');
    let res = await verifyEmail({ token }).catch((err) => {
      setEmailStatus('rejected');
      history.push('/login');
    });
    if (res) setPhoneStatus('phone_resent');
  };

  const onFinish = async (values) => {
    setPhoneStatus('loading');
    let res = await verifyPhoneNumber({
      code: parseInt(values.code),
      token: token,
    }).catch((err) => {
      setErrorMessage('Invalid code provided.');
      setPhoneStatus('phone_rejected');
    });
    if (res) {
      setPhoneStatus('phone_resolved');
      setTimeout(() => {
        history.push('/login');
      }, 6000);
    }
  };

  return (
    <div className="min-h-screen">
      {(emailStatus === 'loading' || phoneStatus === 'loading') && (
        <div className="w-full flex flex-col justify-center p-8 items-center">
          <Spin></Spin>
          <p>Loading...</p>
        </div>
      )}
      {emailStatus === 'error' && (
        <div className="w-full flex justify-center">
          <p>{errorMessage}</p>
        </div>
      )}

      {emailStatus === 'email_resolved' && (
        <div>
          <Form
            name="password_reset_request"
            className="shadow-sm max-w-xl p-8 flex-col mx-auto"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <h2 className="text-xl text-gray-900 mb-6 text-center">
              Phone Number Verification
            </h2>
            <p className="text-center text-md font-bold text-gray-900">
              You need to verify your phone number to continue.
            </p>
            <p className="text-center text-md text-gray-700 mb-4">
              We have sent a code to your phone number. Enter your code to
              verify your account.
            </p>
            {phoneStatus === 'phone_rejected' && (
              <span className="inline-block w-full p-2 text-center py-1 mb-4 bg-red-300 text-red-900 font-bold rounded-sm">
                {errorMessage}
              </span>
            )}
            {phoneStatus === 'phone_resolved' && (
              <span className="inline-block w-full p-2 text-center py-1 mb-4 bg-gray-100 text-green-900 font-bold rounded-sm">
                Your account is verified successfully.{' '}
                <Link to="/login" className="text-blue-400 text-lg">
                  Click to Login
                </Link>
              </span>
            )}
            {phoneStatus === 'phone_resent' && (
              <span className="inline-block w-full p-2 text-center py-1 mb-4 bg-gray-100 text-green-900 font-bold rounded-sm">
                Code resent. Check your phone now.
              </span>
            )}
            {!(phoneStatus === 'phone_resolved') && (
              <>
                <Form.Item
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the code!',
                    },
                  ]}
                >
                  <Input
                    type="number"
                    size="large"
                    prefix={<i className="fal fa-user mr-2" />}
                    placeholder="Enter the code"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button w-full mb-4"
                  >
                    Submit
                  </Button>
                </Form.Item>
                <div className="flex justify-end">
                  <span>
                    Did not receive code?{' '}
                    <Button type="link" className="ml-2" onClick={resendCode}>
                      Resend
                    </Button>
                  </span>
                </div>
              </>
            )}
          </Form>
        </div>
      )}
    </div>
  );
}
