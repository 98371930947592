import React from 'react';
import { Link } from 'react-router-dom';

const CarCard = ({ car, index }) => {
  return (
    <div
      key={index}
      className="flex items-center justify-center w-full sm:w-1/2 lg:w-1/3"
    >
      <div className="w-full py-6 px-3 flex items-center justify-center">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden w-full">
          <Link
            to={{
              pathname: `/car_detail/${car.id}`,
            }}
            className="w-full hover:scale-105 transform transition duration-500"
          >
            <div
              className="bg-cover bg-center h-64 p-4"
              style={{
                backgroundImage: `url(${car['images'][0]})`,
              }}
            ></div>

            <div className="p-4">
              <p className="uppercase tracking-wide text-sm font-bold text-gray-700">
                {car['name']}
              </p>
              <div className="flex items-end">
                <p className="text-3xl text-gray-900 mr-1">
                  ${car['pricePerDay']}
                </p>
                <p className="text-sm text-gray-900">/day</p>
              </div>
              <p className="text-gray-700 text-sm">
                {car['pickUpAndReturnLocation']}
              </p>
            </div>
            <div className="flex p-4 border-t border-gray-300 text-gray-700">
              <div className="flex-1 inline-flex items-center">
                <svg
                  fill="#000000"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="h-6 w-6 text-gray-600 fill-current mr-3"
                >
                  <g>
                    <g>
                      <path
                        d="M506.583,9.847C501.727,3.635,494.286,0,486.402,0h-256c-6.793,0-13.303,2.697-18.099,7.501l-204.8,204.8
			c-4.804,4.796-7.501,11.307-7.501,18.099v256c0,14.14,11.46,25.6,25.6,25.6h409.6c14.14,0,25.6-11.46,25.6-25.6V233.549
			l50.432-201.745C513.145,24.158,511.43,16.06,506.583,9.847z M435.202,486.4h-409.6V256h409.6V486.4z M435.202,230.4h-409.6
			l204.8-204.8h256L435.202,230.4z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <rect x="332.802" y="281.6" width="76.8" height="25.6" />
                    </g>
                  </g>
                </svg>
                <p>
                  <span className="text-gray-900 font-bold">
                    {car['doors']}
                  </span>{' '}
                  Doors
                </p>
              </div>
              <div className="flex-1 inline-flex items-center">
                <svg
                  height="800px"
                  width="800px"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 240.235 240.235"
                  className="h-6 w-6 text-gray-600 stroke-current mr-3"
                >
                  <path
                    d="M211.744,6.089C208.081,2.163,203.03,0,197.52,0h-15.143c-11.16,0-21.811,8.942-23.74,19.934l-0.955,5.436
    c-0.96,5.47,0.332,10.651,3.639,14.589c3.307,3.938,8.186,6.106,13.74,6.106h19.561c2.714,0,5.339-0.542,7.778-1.504l-2.079,17.761
    c-2.001-0.841-4.198-1.289-6.507-1.289h-22.318c-9.561,0-18.952,7.609-20.936,16.961l-19.732,93.027l-93.099-6.69
    c-5.031-0.36-9.231,1.345-11.835,4.693c-2.439,3.136-3.152,7.343-2.009,11.847l10.824,42.618
    c2.345,9.233,12.004,16.746,21.53,16.746h78.049h1.191h39.729c9.653,0,18.336-7.811,19.354-17.411l15.272-143.981
    c0.087-0.823,0.097-1.634,0.069-2.437l5.227-44.648c0.738-1.923,1.207-3.967,1.354-6.087l0.346-4.97
    C217.214,15.205,215.407,10.016,211.744,6.089z"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="11"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p>
                  <span className="text-gray-900 font-bold">
                    {car['seats']}
                  </span>{' '}
                  Seats
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CarCard;
