import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserContext from '../context/UserContext';

const AnonymousGuard = ({ component: Component, ...rest }) => {
  const { user } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        !user ? (
          <Component {...props} />
        ) : user && user.roles?.includes('admin') ? (
          <Redirect to="/admin" />
        ) : (
          <Redirect to="/u" />
        )
      }
    />
  );
};

export default AnonymousGuard;
