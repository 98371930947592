import React, { useEffect, useState, useContext } from 'react';
import { Form, Input, Button, Alert, Spin } from 'antd';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { auth, registerWithEmailAndPassword } from '../../db/firebase';
import { getUser } from '../../db/firebase';
import UserContext from '../../shared/context/UserContext';

function SignUpForm(props) {
  const { setUser } = useContext(UserContext);
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const rtn_url = queryParams.get('rtn_url');
  const start = queryParams.get('start');
  const end = queryParams.get('end');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const [agree, setAgree] = useState(false);
  const [agreeMessage, setAgreeMessage] = useState('');
  const [status, setStatus] = useState('idle');
  const [user, loading, error] = useAuthState(auth);

  const [stateError, setError] = useState('');

  useEffect(() => {
    (async () => {
      if (loading) {
        setStatus('loading');
        return;
      }
      if (error) {
        setStatus('rejected');
        setError('Server error. Try again.');
        return;
      }
      setStatus('idle');
      if (user) {
        let dbUser = await getUser(user.uid);
        if (dbUser) {
          setUser(dbUser);
          setTimeout(() => {
            if (rtn_url) {
              history.push({
                pathname: rtn_url,
                search: `?start=${start}&end=${end}`,
              });
            } else if (dbUser.roles?.includes('admin')) history.push('/admin');
            else history.push('/u');
          }, 500);
        }
      }
    })();
  }, [user, loading, error, history]);

  const onFinish = async (values) => {
    if (!agree) {
      setAgreeMessage('Please read and agree to our policies to continue.');
      return;
    }
    let userInfo = {
      email: values.email,
      name: values.name,
      password: values.password,
      phoneNumber: phoneNumber,
    };
    setStatus('loading');
    await registerWithEmailAndPassword(userInfo).then((res) => {
      if (res['code'] && res['code'] === 'auth/email-already-in-use') {
        console.log('email in use');
        setStatus('rejected');
        setError(
          'Email entered is already in use, please use a different email',
        );
      }
    });
  };

  const handleEmailInput = () => {
    if (stateError?.includes('Email entered is already in use')) {
      setError('');
    }
  };

  return (
    <div className="flex justify-center bg-main-background-color">
      <div className="login_left hidden lg:flex lg:flex-col min-h-screen w-1/2 h-full self-stretch pt-20">
        <h1 className="w-full text-center text-5xl  font-thin my-4 text-primary-color">
          WELCOME
        </h1>
        <h2 className="w-full text-center text-2xl font-thin my-4 text-primary-color">
          TO
        </h2>
        <h1 className="w-full text-center text-5xl font-thin my-4 text-primary-color">
          MRARA
        </h1>
      </div>
      <div className="flex-col mt-2 lg:mt-0 lg:w-1/2 w-full items-center justify-center p-2 lg:p-8 pt-20">
        <div className="shadow-sm max-w-xl p-8 flex-col mx-auto bg-white rounded-3xl">
          <p className="w-full text-center text-3xl font-semibold bg-white max-w-xl mx-auto mb-6 text-gray-700">
            Sign Up
          </p>

          <Form
            name="normal_login"
            // className="shadow-sm max-w-xl p-8 flex-col mx-auto bg-white rounded-b"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            {status === 'rejected' && stateError && (
              <span className="inline-block w-full p-2 text-center py-1 mb-4 bg-red-300 text-red-900 font-bold rounded-sm">
                {stateError}
              </span>
            )}
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input your full name',
                },
              ]}
            >
              <Input
                type="name"
                placeholder="Full Name"
                prefix={<i className="fal fa-user mr-2 font-semibold" />}
                size="large"
                className="rounded-3xl"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
              onChange={handleEmailInput}
            >
              <Input
                type="email"
                size="large"
                prefix={<i className="fal fa-envelope mr-2 font-semibold" />}
                placeholder="email"
                className="rounded-3xl"
              />
            </Form.Item>
            <Form.Item
              className="m-0"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: 'Please enter your phone number!',
                },
              ]}
            >
              <div
                className={`ant-row ant-form-item ${
                  (!phoneNumberValid || phoneNumber?.length > 12) &&
                  'ant-form-item-with-help ant-form-item-has-error'
                }`}
              >
                <div className="ant-col ant-form-item-control">
                  <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content">
                      <span class="ant-input-affix-wrapper ant-input-affix-wrapper-lg rounded-3xl">
                        <span class="ant-input-prefix">
                          <i class="fal fa-phone mr-2 font-semibold"></i>
                        </span>
                        <PhoneInput
                          className="ant-input ant-input-lg"
                          country="US"
                          placeholder="Enter phone number"
                          value={phoneNumber}
                          onChange={(value) => {
                            setPhoneNumber(value);
                            setPhoneNumberValid(true);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  {(!phoneNumberValid || phoneNumber?.length > 12) && (
                    <div class="ant-form-item-explain ant-form-item-explain-connected">
                      <div role="alert" class="ant-form-item-explain-error">
                        Please enter a valid phone number
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input.Password
                size="large"
                prefix={<i className="fal fa-lock mr-2 font-semibold" />}
                type="password"
                placeholder="Password"
                className="rounded-3xl"
              />
            </Form.Item>

            <Checkbox
              className="mb-4 rounded ml-2"
              size="large"
              checked={agree}
              onChange={(e) => {
                setAgreeMessage('');
                setAgree(!agree);
              }}
            >
              I have read and agreed to the{' '}
              <Link
                target="_blank"
                to="/terms_of_use"
                className="text-md text-primary-color hover:text-primary-hover-color mx-1 font-semibold hover:underline"
              >
                Terms of use
              </Link>{' '}
              and{' '}
              <Link
                target="_blank"
                to="/privacy_policy"
                className="text-md text-primary-color hover:text-primary-hover-color mx-1 font-semibold hover:underline"
                type="link"
              >
                Privacy policy
              </Link>
            </Checkbox>
            {agreeMessage && (
              <Alert className="mb-4 p-2" type="error" message={agreeMessage} />
            )}

            <Form.Item>
              {status === 'loading' && (
                <div className="flex justify-center">
                  <Spin />
                </div>
              )}
              {status !== 'loading' && (
                <>
                  <Button
                    size="large"
                    htmlType="submit"
                    type="primary"
                    className="rounded-3xl w-full mb-2 text-white font-semibold text-gray-700"
                  >
                    Sign Up
                  </Button>
                  Already have an account?{' '}
                  <Link
                    to="/login"
                    className="text-md text-black hover:text-gray-800 mx-1 font-semibold hover:underline"
                    // style={{ color: 'black ' }}
                  >
                    Sign In
                  </Link>
                </>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default SignUpForm;
