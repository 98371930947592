import { Skeleton, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
function CardWithImages(props) {
  const color =
    moment(props?.start).isSame(moment(), 'day') &&
    props.status === 'Payment Received'
      ? 'rgba(80, 200, 120,.25)'
      : '';

  const textColor =
    moment(props?.start).isSame(moment(), 'day') &&
    props.status === 'Payment Received'
      ? 'green'
      : '';

  return (
    <Skeleton loading={props.loading} active>
      <div
        className="flex border p-3 justify-between w-full md:w-11/12 mb-3 cursor-pointer hover:shadow  hover:scale-105 transform transition duration-300"
        style={{ borderRadius: '7px' }}
      >
        <div className="">
          <Tag
            color={color}
            className="rounded font-semibold text-xs"
            style={{ color: textColor }}
          >
            {props.time}
          </Tag>
          <p className="font-bold" style={{ fontSize: '16px' }}>
            {props?.carName}
          </p>
          <p className="text-xs font-semibold">{props?.location}</p>
          <p className="text-xs text-gray-500">
            <UserOutlined className="border rounded-full p-1" size="small" />{' '}
            {props?.ownerName}
          </p>
        </div>
        <div>
          <div
            className="bg-cover bg-center md:w-32 w-24 h-16 rounded"
            style={{
              backgroundImage: `url(${props?.src})`,
            }}
          ></div>
          {/* <img
            className="md:w-32 w-24 h-16"
            src={props?.src}
            alt=""
            style={{ borderRadius: '7px' }}
          /> */}
        </div>
      </div>
    </Skeleton>
  );
}

export default CardWithImages;
