import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function CarRevSuccess() {
  const history = useHistory();

  return (
    <div className="flex flex-col h-full justify-center items-center gap-8">
      <div className="flex justify-center">
        <i class="fal fa-check-circle text-9xl text-primary-color"></i>
      </div>
      <div className="flex justify-center text-2xl text-gray-800 font-bold">
        Success! Your request has been processed successfully.
      </div>
      <div>
        <button
          onClick={() => history.push('/u')}
          className="main-btn btn-filled w-full"
        >
          Go to Reservations
        </button>
      </div>
    </div>
  );
}
