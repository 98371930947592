import React, { Fragment, useContext, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Card,
  Table,
  Button,
  Menu,
  Dropdown,
  Popconfirm,
  Tag,
  Pagination,
  Skeleton,
  message,
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import UserContext from '../../shared/context/UserContext';
import {
  editReservation,
  getCarReservations,
  getCarReservationsByUserId,
} from '../../db/firebase';

const Reservations = () => {
  const { user } = useContext(UserContext);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateloading, setUpdateLoading] = useState(false);

  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const [total, setTotal] = useState(0);
  const id = params.get('q');

  useEffect(() => {
    getReservationsLocal();
    history.push({
      pathname: '/admin/reservations',
      search: `?current=${current}&pageSize=${pageSize}&q=${id}`,
    });
  }, [current, pageSize]);

  const getReservationsLocal = async () => {
    setLoading(true);
    let res = id
      ? await getCarReservationsByUserId(id)
      : await getCarReservations(user);
    if (res) {
      setReservations(res);
      setTotal(res.length);
    }
    setLoading(false);
  };

  // const cancelReservationLocal = async (value) => {
  //   const res = await cancelReservation(value._id);
  //   if (res && res.status === 200) {
  //     message.success('Reservation Canceled!!');
  //     await getReservationsLocal();
  //   } else {
  //     message.error('Server error. Try again later.');
  //   }
  // };

  const handleStatusChange = async (value, newStatus) => {
    setUpdateLoading(true);
    let payload = { ...value, status: newStatus };
    delete payload.id;
    try {
      await editReservation(payload, value?.id);
      message.success('Successfully Updated Reservation!');
    } catch (err) {
      message.error('Something went wrong please try again!');
    }
    setUpdateLoading(false);
    getReservationsLocal();
  };

  const menu = (value) => (
    <Menu>
      <p className="px-2 text-sm font-semibold">Change status to:</p>
      {!value.status.includes('Payment Received') && (
        <Menu.Item key="payment_received">
          <Popconfirm
            placement="top"
            title={'Change the status to Payment Received?'}
            onConfirm={() => {
              handleStatusChange(value, 'Payment Received');
            }}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
              loading: updateloading,
            }}
          >
            <Tag color="green">PAYMENT RECEIVED</Tag>
          </Popconfirm>
        </Menu.Item>
      )}
      {!value.status.includes('Active Trip') && (
        <Menu.Item key="active_trip">
          <Popconfirm
            placement="top"
            title={'Change status to Active Trip?'}
            onConfirm={() => {
              handleStatusChange(value, 'Active Trip');
            }}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
              loading: updateloading,
            }}
          >
            <Tag color="geekblue" className="cursor-pointer">
              ACTIVE TRIP
            </Tag>
          </Popconfirm>
        </Menu.Item>
      )}
      {(value.status.includes('Active Trip') ||
        value.status.includes('Payment Received')) && (
        <Menu.Item key="trip_ended">
          <Popconfirm
            placement="top"
            title={'Change status to Trip Ended?'}
            onConfirm={() => {
              handleStatusChange(value, 'Trip Ended');
            }}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
              loading: updateloading,
            }}
          >
            <Tag color="volcano" className="cursor-pointer">
              TRIP ENDED
            </Tag>
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = [
    {
      title: 'Name',
      key: 'carName',
      render: (value) => {
        return value?.carName;
      },
    },
    {
      title: 'Price/Day',
      key: 'pricePerDay',
      render: (value) => {
        return `$${value?.pricePerDay}`;
      },
    },
    {
      title: 'Insurance/Day',
      key: 'insurance',
      render: (value) => {
        return `$${value?.insurancePerDay || 0}`;
      },
    },
    {
      title: 'Check In',
      key: 'checkInDate',
      render: (value) => {
        return `${moment(value.start).format('DD-MM-YYYY hh:mm a')}`;
      },
    },
    // {
    //   title: 'Check In Time',
    //   key: 'checkInTime',
    //   render: (value) => {
    //     return `${moment(value.start).format('hh:mm a')}`;
    //   },
    // },
    {
      title: 'Check Out',
      key: 'checkOutDate',
      render: (value) => {
        return `${moment(value.end).format('DD-MM-YYYY hh:mm a')}`;
      },
    },
    // {
    //   title: 'Check Out Time',
    //   key: 'checkOutTime',
    //   render: (value) => {
    //     return `${moment(value.end).format('hh:mm a')}`;
    //   },
    // },
    {
      title: 'Total',
      key: 'total',
      render: (value) => {
        return value?.total ? `$${value?.total}` : '';
      },
    },
    {
      title: 'Status',
      key: 'status',
      render: (value, index) => {
        let color = '';
        if (value.status.includes('Payment Received')) {
          color = 'green';
        } else if (value.status.includes('Active Trip')) {
          color = 'geekblue';
        } else if (value.status.includes('Trip Ended')) {
          color = 'volcano';
        }
        return (
          <Tag color={color} key={index}>
            {value.status.toUpperCase()}
          </Tag>
        );
      },
      filters: [
        {
          text: 'Payment Received',
          value: 'Payment Received',
        },
        {
          text: 'Active Trip',
          value: 'Active Trip',
        },
        {
          text: 'Trip Ended',
          value: 'Trip Ended',
        },
        {
          text: 'Initiated',
          value: 'Initiated',
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: '',
      key: 'actions',
      render: (value) => {
        return (
          <div>
            {value.status !== 'Trip Ended' && (
              <Dropdown.Button type="primary" loading overlay={menu(value)}>
                Options
              </Dropdown.Button>
            )}
          </div>
        );
      },
      key: 'action',
    },
    ,
  ];

  return (
    <Skeleton className="mt-24 mx-4" loading={loading} active>
      <MetaTags>
        <title>Mrara Enterprises | Car Reservations</title>
        <meta name="description" content="#" />
      </MetaTags>
      <section>
        <div className="flex justify-between items-center pr-8">
          <h2 className="p-4 text-3xl">Car Reservations</h2>
          {id && <Link to="/admin/reservations">View All</Link>}
        </div>
        <Card>
          <div className="flex flex-col items-end">
            <Table
              pagination={false}
              className="min-w-sm w-full overflow-x-auto"
              dataSource={reservations.slice(
                (current - 1) * pageSize,
                (current - 1) * pageSize + pageSize,
              )}
              columns={columns}
            />
            <Pagination
              onChange={(newPage, newPageSize) => {
                setCurrent(newPage);
                setPageSize(newPageSize);
              }}
              className="mt-4 mr-2"
              current={current}
              defaultCurrent={current}
              pageSize={pageSize}
              total={total}
              pageSizeOptions={['5', '10', '15']}
              showTotal={() => `Total ${total} Items`}
              showSizeChanger
            />
          </div>
        </Card>
      </section>
    </Skeleton>
  );
};

export default Reservations;
