import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { TbArrowBadgeRightFilled } from 'react-icons/tb';
import { useHistory } from 'react-router-dom';
import { getModifyRequest, modifyTrip } from '../../db/firebase';
import { Button, Skeleton, message } from 'antd';

export default function ApproveModifyTripRequest() {
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const reqId = queryParams.get('reqId');
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [reqId]);

  async function fetchData() {
    try {
      setLoading(true);
      let res = await getModifyRequest(reqId);
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('An error occurred while fetching data. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  async function handleRequest(payload, isConfirm) {
    try {
      if (isConfirm) {
        setConfirmLoading(true);
      } else {
        setDeclineLoading(true);
      }

      await modifyTrip(payload);
      message.success(isConfirm ? 'Request Confirmed!' : 'Request Declined!');
      history.push(`/car_reservation/success/${reqId}`);
    } catch (error) {
      console.error('Error handling request:', error);
      message.error(
        'An error occurred while processing the request. Please try again.',
      );
    } finally {
      setConfirmLoading(false);
      setDeclineLoading(false);
    }
  }

  async function handleConfirm() {
    const payload = { ...data, status: 'approved' };
    await handleRequest(payload, true);
  }

  async function handleDecline() {
    const payload = { ...data, status: 'rejected' };
    await handleRequest(payload, false);
  }

  return (
    <Skeleton className="mt-24 w-5/6 lg:w-3/5 mx-auto" loading={loading} active>
      <section className="mt-24 py-2 px-4 py-4 shadow-lg w-11/12 md:mt-32 md:w-5/6 lg:w-3/5 mx-auto bg-white rounded-lg mb-10">
        <p className="text-xl lg:text-4xl text-gray-900 font-bold text-center">
          {data?.ownerName} has requested a change to their trip
        </p>
        <p className="mt-6 text-gray-900 text-lg font-semibold text-center">
          Here's what user would like to change:
          <br />
          <strong>_</strong>
        </p>
        <br />

        <p className="text-gray-900 text-lg font-semibold text-center">
          New trip ends on{' '}
          {moment(data.newEnd).format('dddd MMMM Do, YYYY h:mm A')}
        </p>

        <br />
        <p className="text-center font-bold text-gray-900">
          <strong>_</strong>
        </p>
        <p className="mt-4 text-gray-900 text-lg font-semibold text-center">
          If you confirm the new earning will be{' '}
          <strong className="text-gray-800">${data.newTotal}</strong>
        </p>
        <hr className="mx-36 my-4" />
        <p className="font-bold text-gray-900 text-center text-lg py-2 w-full border-b border-black mt-10">
          Booked Trip
        </p>
        <p className="font-bold text-gray-900 text-center text-2xl mt-6">
          {data.carName}
        </p>
        <div className="mt-2 w-1/2 mx-auto">
          <img src={data.src} alt="" className="rounded-lg" />
        </div>
        <div className="flex justify-center gap-10 mt-4">
          <div>
            <p className="font-bold text-xs text-gray-800">TRIP START</p>
            <p className="font-bold text-gray-900">
              {moment(data.start).format('DD/MM/YYYY')}
            </p>
            <p className="text-gray-600 font-bold text-sm">
              {moment(data.start).format('h:mm A')}
            </p>
          </div>
          <div className="pt-2">
            <TbArrowBadgeRightFilled className="text-3xl text-black font-bold" />
          </div>
          <div>
            <p className="font-bold text-xs text-gray-800">TRIP END</p>
            <p className="font-bold text-gray-900">
              {moment(data.newEnd).format('DD/MM/YYYY')}
            </p>
            <p className="text-gray-600 font-bold text-sm">
              {moment(data.newEnd).format('h:mm A')}
            </p>
            <p className="line-through text-sm">
              {moment(data.oldEnd).format('DD/MM/YYYY h:mm A')}
            </p>
          </div>
        </div>
        <p className="text-gray-900 text-center font-semibold mt-4">
          You earn <strong className="text-gray-800">${data?.newTotal}</strong>{' '}
          <span className="text-sm text-gray-600 line-through font-semibold">
            ${data?.oldTotal}
          </span>
        </p>
        <p className="text-gray-900 text-center font-semibold mt-4">
          Charge the renter an extra{' '}
          <strong className="text-gray-800">${data?.priceDiff}</strong>
        </p>

        <div className="flex flex-col gap-2 mt-4 px-16">
          <Button
            loading={confirmLoading}
            type="primary"
            onClick={handleConfirm}
            disabled={declineLoading}
          >
            Confirm
          </Button>
          <Button
            type="secondary"
            className="bg-gray-100"
            onClick={handleDecline}
            loading={declineLoading}
            disabled={confirmLoading}
          >
            Decline
          </Button>
        </div>
      </section>
    </Skeleton>
  );
}
