import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Dropdown, Menu } from 'antd';
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { logoutFirebase } from '../../db/firebase';
import UserContext from '../../shared/context/UserContext';
import EditProfileModal from './EditProfileModal';
import EditPreferencesModal from './EditPreferencesModal';
import { fetchPreferences } from '../../db/firebase';

const ProfileDropdown = () => {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openPreferenceModal, setOpenPreferenceModal] = useState(false);
  const [preferences, setPreferences] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    await fetchPreferences().then((res) => setPreferences(res[0]));
  };

  const logout = () => {
    logoutFirebase();
    setUser(null);
    localStorage.removeItem('user');
    history.push('/');
  };

  const menu = useMemo(
    () => (
      <>
        <Menu className="pt-2 rounded-lg mr-1 shadow-lg">
          <div className="flex mb-2">
            <div className="border px-2 ml-2 rounded-full">
              <UserOutlined className="text-2xl" />
            </div>
            <div className="flex flex-col px-2">
              <p className="text-lg ">
                {user?.displayName || user?.name}{' '}
                {user?.roles?.includes('admin') ? (
                  <strong className="text-xs">(Admin)</strong>
                ) : (
                  ''
                )}
              </p>
              <p className="text-xs">{user?.email}</p>
            </div>
          </div>

          <Menu.Divider />
          {/* <Menu.Item
            key="profile"
            icon={<UserOutlined className="hover:text-white" />}
            onClick={() => setOpenProfileModal(true)}
            className="hover:bg-gray-200 font-semibold hover:text-white min-h-80"
          >
            <p>My Profile</p>
          </Menu.Item> */}
          {user?.roles?.includes('admin') && (
            <>
              <Menu.Item
                key="preferences"
                icon={<SettingOutlined />}
                onClick={() => setOpenPreferenceModal(true)}
                className="hover:bg-gray-100 font-semibold hover:text-white"
              >
                Preferences
              </Menu.Item>
              <Menu.Divider />
            </>
          )}

          <Menu.Item
            key="logout"
            icon={<LogoutOutlined className="font-semibold" />}
            onClick={logout}
            className="hover:bg-gray-100 font-semibold hover:text-white"
          >
            Logout
          </Menu.Item>
        </Menu>
      </>
    ),
    [user],
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
        {/* <a className="ant-dropdown-link flex mr-6 rounded-full pt-1" href="#"> */}
        <UserOutlined className="text-xl text-black focus:text-primary-color mr-6" />
        {/* {user?.roles?.includes('admin') ? (
          // <RiAdminLine className="text-xl text-black focus:text-primary-color" />
          <UserOutlined className="text-xl text-black focus:text-primary-color mr-6" />
        ) : (
          <RiUserLine className="text-xl text-black focus:text-primary-color mr-6" />
        )} */}

        {/* <p className="text-gray-500  rounded-lg text-md text-sm font-bold">
            {user?.displayName || user?.name}
          </p> */}
        {/* </a> */}
      </Dropdown>
      <EditProfileModal setOpen={setOpenProfileModal} open={openProfileModal} />
      <EditPreferencesModal
        setOpen={setOpenPreferenceModal}
        open={openPreferenceModal}
        preferences={preferences}
        fetch={fetch}
      />
    </>
  );
};

export default React.memo(ProfileDropdown);
