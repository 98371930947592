import { Button, Card, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getCars } from '../../db/firebase';
import CarCard from './CarCard';

const { Meta } = Card;

const SearchResult = () => {
  const [availableCars, setAvailableCars] = useState([]);
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const [loading, setLoading] = useState(false);
  const [resultsFetched, setResultsFetched] = useState(false);

  const start = queryParams.get('start');
  const end = queryParams.get('end');

  useEffect(() => {
    fetchCars();
  }, []);

  const fetchCars = async () => {
    setLoading(true);
    setResultsFetched(false);
    let res = await getCars();
    // console.log(res);
    if (res) {
      setAvailableCars(res);
    }
    setResultsFetched(true);
    setLoading(false);
  };

  return (
    <section className="pb-40 bg-white min-h-screen">
      <div className="container">
        <div className="section-title text-center mb-4 pt-6 lg:pt-0">
          <h2>Available Cars</h2>
        </div>
        <div className="flex flex-wrap">
          {availableCars?.map((car, i) => {
            return <CarCard car={car} index={i} loading={loading} />;
          })}
        </div>
        {loading && (
          <div className="flex justify-center pt-10 w-full">
            <Spin size="large" />
          </div>

          // <div className="flex justify-center flex-wrap gap-3 w-full">
          //   {[1, 2, 3, 4, 5, 6].map((i) => (
          //     <Card
          //       style={{
          //         height: 300,
          //       }}
          //       loading={loading}
          //       className="w-full sm:w-1/2 lg:w-1/4 p-8"
          //     >
          //       <Meta
          //         title="Card title"
          //         description="This is the description"
          //       />
          //     </Card>
          //   ))}
          // </div>
        )}
        {resultsFetched && !loading && availableCars?.length === 0 && (
          <div className="flex-col items-center text-center">
            <p>
              Something went wrong, please check your connection and try again!
            </p>
            <Button
              onClick={() => {
                history.push({
                  pathname: '/',
                  // search: `?start=${start || ''}&end=${end || ''}`,
                });
              }}
            >
              Go Back
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default SearchResult;
