import React from 'react';
// import PropTypes from 'prop-types'
import { MetaTags } from 'react-meta-tags';
import PasswordResetForm from '../components/PasswordResetForm';

function PasswordReset(props) {
  return (
    <div className="min-h-screen">
      <MetaTags>
        <title>Mrara Enterprises | Password Reset</title>
        <meta name="description" content="#" />
      </MetaTags>
      <section>
        <PasswordResetForm />
      </section>
    </div>
  );
}

export default PasswordReset;
