import React, { useEffect, useState, useMemo } from 'react';
import { Button, message, Modal } from 'antd';
import { handleModifyRequest } from '../../db/firebase';
import { DatePicker } from 'react-rainbow-components';
import { FiClock } from 'react-icons/fi';
import moment from 'moment';

function generateTimeOptions() {
  let options = [];
  let intervals = [0, 30];
  for (let hour = 0; hour < 24; hour++) {
    for (let interval of intervals) {
      let hour12 = hour % 12 || 12;
      hour12 = hour12 < 10 ? '0' + hour12 : hour12;
      interval = interval < 10 ? '0' + interval : interval;
      let suffix = hour < 12 ? 'AM' : 'PM';
      let value = hour12 + ':' + interval + ' ' + suffix;
      let label = value;
      if (value === '12:00 AM') {
        label = 'Midnight';
      }
      if (value === '12:00 PM') {
        label = 'Noon';
      }
      let option = { value, label };
      options.push(option);
    }
  }
  return options;
}

function ModifyTripModal({
  visible,
  handleClose,
  reservation,
  setClickedRev,
  allRevs,
}) {
  const timeOptions = useMemo(() => generateTimeOptions(), [reservation]);
  const [checkoutDate, setCheckoutDate] = useState(new Date(reservation?.end));
  const [checkoutTime, setCheckoutTime] = useState(
    moment(reservation?.end).format('h:mm A'),
  );
  const [isCheckoutChanged, setIsCheckoutChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const bool = isDateTheSame(checkoutDate, reservation.end, checkoutTime);
    if (bool) {
      setIsCheckoutChanged(false);
    } else {
      setIsCheckoutChanged(true);
    }
  }, [checkoutDate, checkoutTime]);

  const handleOk = async () => {
    try {
      setLoading(true);
      const payload = {
        newCheckout: moment(
          moment(checkoutDate).format('YYYY-MM-DD') +
            ' ' +
            (checkoutTime || '10:00 AM'),
          'YYYY-MM-DD h:mm A',
        ).format('YYYY-MM-DDTHH:mm:ss'),
        reservation: reservation,
      };
      await handleModifyRequest(payload);
      setClickedRev((prevState) => ({
        ...prevState,
        modifyRequest: 'pending',
      }));
      message.success('Request sent successfully!');
    } catch (error) {
      console.error('Error modifying request:', error);
      message.error(
        'An error occurred while modifying the request. Please try again.',
      );
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  function isDateTheSame(date1, date2, time) {
    return moment(
      moment(
        moment(date1).format('YYYY-MM-DD') + ' ' + (time || '10:00 AM'),
        'YYYY-MM-DD h:mm A',
      ).format('YYYY-MM-DDTHH:mm:ss'),
    ).isSame(date2);
  }

  const isDateDisabled = (date) => {
    return allRevs.some((rev) => {
      if (rev.status === 'Trip Ended') {
        return false;
      }
      const startDate = moment(rev.start);
      const endDate = moment(rev.end);
      return date.isBetween(startDate, endDate, 'day', '[]'); // '[]' means inclusive
    });
  };

  const getDisabledDays = () => {
    const disabledDays = [];
    const startDate = moment().startOf('day');
    const endDate = moment().add(1, 'year').endOf('day'); // Example range for a year

    let date = startDate;
    while (date.isBefore(endDate)) {
      if (isDateDisabled(date)) {
        disabledDays.push(date.toDate());
      }
      date = date.add(1, 'day');
    }
    return disabledDays;
  };

  return (
    <div>
      <Modal
        title="Extend Trip"
        visible={visible}
        // onOk={() => {}}
        onCancel={() => handleClose()}
        footer={
          <div className="flex gap-2 justify-end">
            <Button type="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleOk}
              disabled={!isCheckoutChanged}
              loading={loading}
            >
              Confirm
            </Button>
          </div>
        }
        style={{ borderRadius: '100px' }}
      >
        <div>
          <p className="ml-2 font-semibold text-gray-700">
            Change checkout date:
          </p>
          <div className="flex gap-2 w-full">
            <div className="w-3/5">
              <DatePicker
                value={checkoutDate}
                onChange={(value) => {
                  setCheckoutDate(value);
                }}
                formatStyle="medium"
                minDate={new Date(reservation?.end)}
                // disabledDays={getDisabledDays()}
              />
            </div>
            <div className="w-2/5 relative">
              <FiClock className="absolute right-2 top-3 text-lg" />
              <select
                value={checkoutTime}
                onChange={(e) => setCheckoutTime(e.target.value)}
                id="checkout"
                className="w-full"
              >
                {timeOptions.map((option, index) => {
                  return (
                    <option
                      key={index}
                      value={option.value}
                      className="text-bold"
                    >
                      {option.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ModifyTripModal;
