import React, { useState } from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { resetPassword } from '../../shared/services/userService';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

function PasswordResetForm(props) {
  const history = useHistory();

  const queryParams = new URLSearchParams(history.location.search);
  const token = queryParams.get('token');
  const [passwordReset, setPasswordReset] = useState(false);

  const [error, setError] = useState('');

  const onFinish = async ({ password, confirm_password }) => {
    if (password !== confirm_password) {
      setError('Passwords do not match!');
      return;
    }
    setError('');
    let res = await resetPassword({ password, token }).catch((err) =>
      console.log(err),
    );
    if (res && res.status === 200) {
      setPasswordReset(true);
    } else {
      setError('Can not reset password');
    }
  };
  return (
    <div className="flex justify-center">
      <div className="flex-col lg:w-1/2 w-full items-center justify-center p-2 lg:p-8 pt-20">
        <h1 className="w-full text-center text-4xl font-bold">
          Reset Your Password
        </h1>
        {passwordReset && (
          <div className="my-2 max-w-sm mx-auto text-center">
            <Alert type="success" message="Password reset successfully!!" />
          </div>
        )}

        {!passwordReset && (
          <Form
            name="password_reset_request"
            className="shadow-sm max-w-xl p-8 flex-col mx-auto"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            {error && (
              <span className="inline-block w-full p-2 text-center py-1 mb-4 bg-red-300 text-red-900 font-bold rounded-sm">
                {error}
              </span>
            )}
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input
                size="large"
                prefix={<i className="fal fa-lock mr-2" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: 'Please input your confirm password!',
                },
              ]}
            >
              <Input
                size="large"
                prefix={<i className="fal fa-lock mr-2" />}
                type="password"
                placeholder="Confirm Password"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button w-full mb-4"
              >
                Reset Password
              </Button>
              <Link to="/login">Sign In</Link> or{' '}
              <Link to="/sign_up">register now!</Link>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
}

export default PasswordResetForm;
