import React, { useState } from 'react';
import { Button, Modal, Form, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { addPhoneNumber } from '../../db/firebase';
import PhoneInput from 'react-phone-number-input/input';
import { getUser } from '../../db/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../db/firebase';
import { logoutFirebase, reserveCar } from '../../db/firebase';

const AddPhNumModal = ({
  open,
  setOpen,
  setUser,
  signedIn,
  dates,
  car,
  stripe,
  setStatus,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const queryParams = new URLSearchParams(history.location.search);
  const rtn_url = queryParams.get('rtn_url');
  const start = queryParams.get('start');
  const end = queryParams.get('end');
  const [user] = useAuthState(auth);

  const handleOk = async () => {
    setLoading(true);
    setError(false);
    await addPhoneNumber({ phoneNumber: phoneNumber }).then(async (res) => {
      if (res.hasOwnProperty('errorInfo')) {
        setLoading(false);
        setError(true);
        setErrorText(res.errorInfo?.message);
      } else {
        if (signedIn) {
          message.success('Phone number added!');
          setOpen(false);
          setStatus('pending');
          const startDate = dates.start;
          const endDate = dates.end;
          let res = await reserveCar({
            ...car,
            start: startDate,
            end: endDate,
          });
          if (res?.id) {
            stripe.redirectToCheckout({ sessionId: res.id });
            setStatus('resolved');
          } else {
            message.error(res?.message);
            setStatus('resolved');
          }
          console.log(res);
        } else {
          let dbUser = await getUser(user.uid);
          setUser(dbUser);
          setTimeout(() => {
            if (rtn_url) {
              history.push({
                pathname: rtn_url,
                search: `?start=${start}&end=${end}`,
              });
            } else if (dbUser.roles?.includes('admin')) history.push('/admin');
            else history.push('/u');
          }, 500);
        }
      }
    });
  };

  const handleCancel = () => {
    if (signedIn) {
      setOpen(false);
      message.error('Can not book without adding a phone number!');
      return;
    }
    setOpen(false);
    logoutFirebase();
    setUser(null);
    localStorage.removeItem('user');
  };

  return (
    <>
      <Modal
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        className="p-0"
      >
        <Form
          name="normal_login"
          // className="shadow-sm max-w-xl p-8 flex-col m-0"
          initialValues={{
            remember: true,
          }}
          onFinish={handleOk}
          className="p-0"
        >
          <p className="text-lg font-bold mb-1">Add Phone Number</p>
          <Form.Item
            className="m-0"
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: 'Please enter your phone number!',
              },
            ]}
          >
            <div
              className={`ant-row ant-form-item ${
                (!phoneNumberValid || phoneNumber?.length > 12) &&
                'ant-form-item-with-help ant-form-item-has-error'
              }`}
            >
              <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                  <div className="ant-form-item-control-input-content">
                    <span class="ant-input-affix-wrapper ant-input-affix-wrapper-lg">
                      <span class="ant-input-prefix">
                        <i class="fal fa-phone mr-2"></i>
                      </span>
                      <PhoneInput
                        className="ant-input ant-input-lg"
                        country="US"
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        onChange={(value) => {
                          setPhoneNumber(value);
                          setPhoneNumberValid(true);
                          setError(false);
                        }}
                      />
                    </span>
                  </div>
                </div>
                {error && <div className="text-red-500">{errorText}</div>}
                {(!phoneNumberValid || phoneNumber?.length > 12) && (
                  <div class="ant-form-item-explain ant-form-item-explain-connected">
                    <div role="alert" class="ant-form-item-explain-error">
                      Please enter a valid phone number
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Form.Item>
          <div className="flex justify-center gap-4">
            {/* <Button key="back" onClick={handleCancel}>
              Do It Later
            </Button> */}
            <Button
              key="submit"
              // type="primary"
              className="bg-primary-color hover:bg-primary-hover-color text-white hover:border-primary-color"
              loading={loading}
              htmlType="submit"
            >
              Add
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddPhNumModal;
