import React, { useEffect, useState } from 'react';
import { Modal, Form, message } from 'antd';
import { updatePreferences } from '../../db/firebase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditPreferencesModal = ({ open, setOpen, preferences, fetch }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      reservation_sms: preferences?.reservation_sms,
    });
  }, [form, preferences]);

  const onFinish = async (values) => {
    try {
      setConfirmLoading(true);
      await updatePreferences(values);
      setOpen(false);
      setConfirmLoading(false);
      fetch();
      message.success('Saved!');
    } catch (error) {
      console.error('Error updating preferences:', error);
      setConfirmLoading(false);
      message.error('Error updating, please try again');
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        title="Edit Preferences"
        visible={open}
        onOk={form.submit}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label="Reservation SMS"
            name="reservation_sms"
            rules={[
              {
                required: true,
                message: 'Please input your reservation SMS!',
              },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{
              marginBottom: 0,
            }}
          >
            <ReactQuill theme="snow" />
          </Form.Item>
        </Form>
        <p className="text-xs text-right text-red-500">
          {'Always include "${user_name}" where the user name should be!'}
        </p>
      </Modal>
    </>
  );
};

export default EditPreferencesModal;
