import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import logo from '../../assets/img/logo.png';
import Canvas from '../../shared/components/Canvas';
import { withRouter, useHistory } from 'react-router-dom';
import UserContext from '../../shared/context/UserContext';
import ProfileDropdown from '../../shared/components/ProfileDropdown';
import { logoutFirebase } from '../../db/firebase';
import Notification from './Notification';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';

const navigationmenu = [
  {
    id: 1,
    linkText: 'Home',
    child: false,
    link: '/',
  },
  {
    id: 2,
    linkText: 'Services',
    child: true,
    submenu: [
      {
        id: 11,
        link: '/search_results',
        linkText: 'Car Rental',
      },
      // {
      //   id: 12,
      //   link: '/apartment_sharing',
      //   linkText: 'Vacation Rental',
      // },
    ],
  },
  {
    id: 3,
    linkText: 'Users',
    link: '/admin/users',
  },
  {
    id: 4,
    linkText: 'Reservations',
    link: '/u',
    // child: true,
    // submenu: [
    //   {
    //     id: 14,
    //     link: '/u',
    //     linkText: 'Car Reservations',
    //   },
    // {
    //   id: 16,
    //   link: '/u/apartment_reservations',
    //   linkText: 'Apartment Reservations',
    // },
    // ],
  },
  {
    id: 5,
    linkText: 'Products',
    link: '/admin',
    // child: true,
    // submenu: [
    //   {
    //     id: 17,
    //     link: '/admin',
    //     linkText: 'Cars',
    //   },
    // {
    //   id: 18,
    //   link: '/admin/apartments',
    //   linkText: 'Apartments',
    // },
    // ],
  },
  // {
  //   id: 6,
  //   linkText: 'Test',
  //   child: false,
  //   link: '/u/test',
  // },
  {
    id: 6,
    linkText: 'Sign In',
    child: false,
    link: '/login',
  },
  {
    id: 7,
    linkText: 'Logout',
    child: false,
    link: '/',
  },
];

const Header = ({ phoneNumber }) => {
  const history = useHistory();
  const location = useLocation();
  const [classMethod, setClassMethod] = useState(false);
  const [toggleMethod, setToggleMethod] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTop, setIsTop] = useState(false);

  const { user, setUser } = useContext(UserContext);

  const resize = useCallback(() => {
    setIsMobile(window.innerWidth < 991);
  }, []);

  const load = useCallback(() => {
    setIsMobile(window.innerWidth < 991);
  }, []);

  const scroll = useCallback(() => {
    setIsTop(window.scrollY > 110);
  }, []);

  const popstate = useCallback(() => {
    setIsMobile(window.innerWidth < 991);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resize, false);
    window.addEventListener('load', load, false);

    window.addEventListener('scroll', scroll, false);

    window.addEventListener('popstate', popstate, false);
    return () => {
      window.removeEventListener('resize', resize, false);
      window.removeEventListener('load', load, false);
      window.removeEventListener('scroll', scroll, false);
      window.removeEventListener('popstate', popstate, false);
    };
  }, [resize, load, scroll, popstate]);

  const addClass = useCallback(() => {
    setClassMethod(true);
  }, []);

  const removeClass = useCallback(() => {
    setClassMethod(false);
  }, []);

  const toggleClass = useCallback(() => {
    setToggleMethod((prevToggle) => !prevToggle);
  }, []);

  const logout = useCallback(() => {
    logoutFirebase();
    setUser(null);
    localStorage.removeItem('user');
    history.push('/');
  }, [setUser, history]);

  useEffect(() => {
    if (window.innerWidth < 991 && !isMobile) {
      setIsMobile(window.innerWidth < 991);
    }
  }, [isMobile]);

  const getNextSibling = (elem, selector) => {
    // Get the next sibling element
    var sibling = elem.nextElementSibling;
    // If there's no selector, return the first sibling
    if (!selector) return sibling;
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }
  };

  const triggerChild = (e) => {
    let subMenu = '';
    subMenu =
      getNextSibling(e.target, '.submenu') !== undefined
        ? getNextSibling(e.target, '.submenu')
        : null;
    if (subMenu !== null && subMenu !== undefined && subMenu !== '') {
      subMenu.classList = subMenu.classList.contains('d-block')
        ? 'submenu'
        : 'submenu d-block';
    } else {
      toggleClass();
    }
  };

  const className = isMobile ? 'breakpoint-on' : '';
  const classNamess = isMobile ? 'd-none' : '';
  const stickyheader = isTop ? 'sticky-active' : '';

  return (
    <Fragment>
      <header
        className={`header-absolute sticky-header ${stickyheader} shadow-sm`}
        id="header"
      >
        <div className="container container-custom-one">
          <div
            className={`nav-container d-flex align-items-center justify-content-between ${className}`}
          >
            {/* Main Menu */}
            <div
              className={classNames(
                'relative nav-menu d-lg-flex align-items-center',
                {
                  'menu-on': toggleMethod,
                },
              )}
            >
              {/* Navbar Close Icon */}
              <div className="navbar-close" onClick={toggleClass}>
                <div className="cross-wrap">
                  <span className="top" />
                  <span className="bottom" />
                </div>
              </div>
              {/* Off canvas Menu  */}
              {/* <div className="toggle">
                <Link to="#" id="offCanvasBtn" onClick={addClass}>
                  <i className="fal fa-bars" />
                </Link>
              </div> */}
              {/* Mneu Items */}
              <div className="menu-items">
                {isMobile && user && (
                  <div className="flex mb-2 text-white pl-2 mb-4">
                    <div className="border px-2 ml-2 rounded-full">
                      <UserOutlined className="text-2xl" />
                    </div>
                    <div className="flex flex-col px-2">
                      <p className="text-lg ">
                        {user?.displayName || user?.name}{' '}
                        {user?.roles?.includes('admin') ? (
                          <strong className="text-xs">(Admin)</strong>
                        ) : (
                          ''
                        )}
                      </p>
                      <p className="text-xs">{user?.email}</p>
                    </div>
                  </div>
                )}
                <ul className="lg:ml-10">
                  {navigationmenu.length > 0
                    ? navigationmenu.map((item, i) => {
                        const isActive = location.pathname === item.link;
                        if (
                          (item.linkText === 'Products' ||
                            item.linkText === 'Test') &&
                          !user?.roles?.includes('admin')
                        )
                          return '';
                        if (item.linkText === 'Sign In' && (user || !isMobile))
                          return '';
                        if (item.linkText === 'Logout' && (!user || !isMobile))
                          return '';
                        if (item.linkText === 'Reservations' && !user)
                          return '';
                        if (
                          item.linkText === 'Users' &&
                          !user?.roles?.includes('admin')
                        )
                          return '';
                        if (
                          item.linkText === 'Services' &&
                          user?.roles?.includes('admin')
                        )
                          return '';
                        return (
                          <li
                            key={i}
                            className={`menu-item ${
                              item.child ? 'menu-item-has-children' : ''
                            } `}
                            onClick={(e) => {
                              if (item.linkText === 'Logout' && user) logout(e);
                              else triggerChild(e);
                            }}
                          >
                            {item.child ? (
                              <Link onClick={(e) => e.preventDefault()} to="/">
                                {' '}
                                {item.linkText}{' '}
                              </Link>
                            ) : (
                              <Link
                                className={`${
                                  item.linkText === 'Logout' ||
                                  item.linkText === 'Sign In'
                                    ? 'lg:invisible'
                                    : ''
                                }`}
                                to={item.link}
                              >
                                <div className="flex items-center gap-2">
                                  {item.linkText === 'Logout' && (
                                    <LogoutOutlined className="text-lg" />
                                  )}{' '}
                                  <p
                                    className={`${
                                      isActive ? 'active-tab font-bold' : ''
                                    }`}
                                  >
                                    {item.linkText}
                                  </p>
                                </div>
                              </Link>
                            )}
                            {item.child ? (
                              <ul className="submenu" role="menu">
                                {item.submenu.map((sub_item, i) => (
                                  <li
                                    key={i}
                                    className={`menu-item ${
                                      sub_item.child
                                        ? 'menu-item-has-children'
                                        : ''
                                    } `}
                                  >
                                    {sub_item.child ? (
                                      <Link
                                        onClick={(e) => e.preventDefault()}
                                        to="/"
                                      >
                                        {' '}
                                        {sub_item.linkText}{' '}
                                      </Link>
                                    ) : (
                                      <Link to={sub_item.link}>
                                        {' '}
                                        {sub_item.linkText}{' '}
                                      </Link>
                                    )}
                                    {sub_item.submenu ? (
                                      <ul className="submenu">
                                        {sub_item.submenu.map(
                                          (third_item, i) => (
                                            <li key={i}>
                                              <Link to={third_item.link}>
                                                {third_item.linkText}
                                              </Link>
                                            </li>
                                          ),
                                        )}
                                      </ul>
                                    ) : null}
                                  </li>
                                ))}
                              </ul>
                            ) : null}
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
              {/* from pushed-item */}
              <div className="nav-pushed-item" />
            </div>
            {/* Site Logo */}
            {isMobile && (
              <div
                className={classNames('navbar-toggler', {
                  active: toggleMethod,
                })}
                onClick={toggleClass}
              >
                <span />
                <span />
                <span />
              </div>
            )}
            <div className="header_logo w-28">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>
            {/* Header Info Pussed To Menu Wrap */}
            <div className={`nav-push-item ${classNamess}`}>
              {/* Header Info */}
              <div className="header-info d-lg-flex align-items-center font-bold text-primary-color">
                {user ? (
                  <div className="flex items-center gap-4">
                    <Notification />
                    {!isMobile && <ProfileDropdown />}
                  </div>
                ) : (
                  <>
                    <div className="flex items-center text-lg ml-4">
                      <i className="fal fa-phone mr-2" />
                      <Link to="tel:+14694593574">
                        <h5 className="title text-primary-color">
                          +1 (469) 459-3574
                        </h5>
                      </Link>
                    </div>
                    <div className="flex items-center text-lg ml-4">
                      <i className="fal fa-sign-in-alt text-2xl mr-2"></i>
                      <Link to="/login">
                        <h5 className="title text-primary-color">Sign In</h5>
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* Navbar Toggler */}
            {/* <div className="flex"> */}
            {isMobile && (
              <div className="flex items-center">
                <Notification />
              </div>
            )}

            {/* <div className="lg:invisible">
                <Link to="/login">
                  <i className="fal fa-sign-in-alt text-2xl mr-2"></i>
                </Link>
              </div> */}
            {/* </div> */}
          </div>
        </div>
      </header>
      {/* <div
        className={classNames('offcanvas-wrapper', {
          'show-offcanvas': classMethod,
        })}
      >
        <div
          className={classNames('offcanvas-overly', {
            'show-overly': classMethod,
          })}
          onClick={removeClass}
        />
        <div className="offcanvas-widget">
          <Link to="#" className="offcanvas-close" onClick={removeClass}>
            <i className="fal fa-times" />
          </Link>
          <Canvas />
        </div>
      </div> */}
    </Fragment>
  );
};

export default withRouter(React.memo(Header));
