import React, { Fragment, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, Table, Spin, Menu, Dropdown, Pagination, Input } from 'antd';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { getUsers } from '../../db/firebase';
import { SearchOutlined } from '@ant-design/icons';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const [total, setTotal] = useState(0);

  const [current, setCurrent] = useState(params.get('current') || 1);
  const [pageSize, setPageSize] = useState(params.get('pageSize') || 10);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    fetchUsers();
    history.push({
      pathname: '/admin/users',
      search: `?current=${current}&pageSize=${pageSize}`,
    });
  }, [current, pageSize]);

  const menu = (value) => (
    <Menu>
      <Menu.Item key="Car Reservations">
        <Link
          rel="noopener noreferrer"
          to={`/admin/reservations?q=${value.uid}`}
        >
          Car Reservations
        </Link>
      </Menu.Item>
      {/* <Menu.Item key="Disable user">
        <p className="text-red-500" onClick={() => handleDisable(value)}>
          Disable user
        </p>
      </Menu.Item> */}
    </Menu>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value) => value || 'ACTIVE',
    },
    // {
    //   title: 'Created At',
    //   key: 'createdAt',
    //   render: (value) => {
    //     return moment(value.createdAt).format('DD-MM-YYYY');
    //   },
    // },
    {
      title: '',
      render: (value) => {
        return (
          <div className="w-12">
            <Dropdown.Button type="primary" overlay={menu(value)}>
              Options
            </Dropdown.Button>
          </div>
        );
      },
      key: 'action',
    },
  ];

  const fetchUsers = async () => {
    setLoading(true);
    let res = await getUsers();
    if (res) {
      setUsers(res);
      setOriginalUsers(res);
      setTotal(res.length);
    } else {
      console.log(res);
      setError('Server error try later.');
    }
    setLoading(false);
  };

  // async function handleDisable(value) {
  //   await disableUser({ uid: value.uid, operation: 'disable' }).then((res) =>
  //     console.log(res),
  //   );
  // }

  const handleSearch = (e) => {
    const currValue = e.target.value;
    setValue(currValue);
    if (currValue === '') {
      setUsers(originalUsers);
    } else {
      const filteredData = originalUsers.filter((entry) =>
        entry?.displayName?.toLowerCase().includes(currValue.toLowerCase()),
      );
      setUsers(filteredData);
    }
  };

  return (
    <Fragment>
      <MetaTags>
        <title>Mrara Enterprises | Users</title>
        <meta name="description" content="#" />
      </MetaTags>
      <section className="mt-24" style={{ minHeight: '20rem' }}>
        {!loading && !error && (
          <>
            {/* <div className="flex pl-2 md:pl-6 items-center w-full md:w-5/6 mx-auto">
              
            </div> */}
            {/* <Card> */}
            <div className="flex flex-col items-end w-full md:w-11/12 lg:w-5/6 mx-auto px-2">
              <div className="flex flex-col md:flex-row justify-between w-full gap-4">
                <h1 className="text-4xl">Users</h1>
                <Input
                  placeholder="Search User Name"
                  value={value}
                  onChange={handleSearch}
                  className="w-3/4 md:w-1/4 mb-4 shadow-sm rounded-xl"
                  prefix={<SearchOutlined />}
                  size="large"
                />
              </div>

              <Table
                className="min-w-sm w-full overflow-x-auto rounded-xl shadow-lg"
                pagination={false}
                dataSource={users.slice(
                  (current - 1) * pageSize,
                  (current - 1) * pageSize + pageSize,
                )}
                columns={columns}
              />
              {!value && (
                <Pagination
                  onChange={(newPage, newPageSize) => {
                    setCurrent(newPage);
                    setPageSize(newPageSize);
                  }}
                  className="my-4 mr-2"
                  current={current}
                  defaultCurrent={current}
                  pageSize={pageSize}
                  total={total}
                  pageSizeOptions={['2', '5', '10', '15']}
                  showTotal={() => `Total ${total} Items`}
                  showSizeChanger
                />
              )}
            </div>
            {/* </Card> */}
          </>
        )}
        {loading && (
          <div className="w-full mt-32 flex justify-center">
            <Spin size="large" />
          </div>
        )}
        {error && <p className="text-center text-lg text-gray-700">{error}</p>}
      </section>
    </Fragment>
  );
};

export default Users;
