import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    const scrollToTop = () => {
      const rootElement = document.window || document.body;
      rootElement.style.scrollBehavior = 'smooth';
      rootElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      rootElement.style.scrollBehavior = 'auto';
    };

    scrollToTop();
  }, [pathname]);
}

export default useScrollToTop;
