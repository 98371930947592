import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Checkbox, ConfigProvider } from 'antd';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { BsGoogle } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';
import {
  auth,
  getUser,
  logInWithEmailAndPassword,
  signInWithGoogle,
} from '../../db/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import UserContext from '../../shared/context/UserContext';
import AddPhNumModal from '../../auth-user/components/AddPhNumModal';

function LoginForm(props) {
  const { setUser } = useContext(UserContext);
  const history = useHistory();

  const queryParams = new URLSearchParams(history.location.search);
  const rtn_url = queryParams.get('rtn_url');
  const start = queryParams.get('start');
  const end = queryParams.get('end');
  const [user, loading, error] = useAuthState(auth);

  const [stateError, setError] = useState('');
  const [status, setStatus] = useState('idle');

  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (error) {
        setStatus('rejected');
        setError('Server error. Try again.');
      }
      if (loading) {
        setStatus('loading');
        return;
      }
      setStatus('idle');
      if (user) {
        let dbUser = await getUser(user.uid);
        if (dbUser && dbUser?.phoneNumber) {
          setUser(dbUser);
          setTimeout(() => {
            if (rtn_url) {
              history.push({
                pathname: rtn_url,
                search: `?start=${start}&end=${end}`,
              });
            } else if (dbUser.roles?.includes('admin')) history.push('/admin');
            else history.push('/u');
          }, 500);
        } else {
          setOpen(true);
        }
      }
    })();
    // eslint-disable-next-line
  }, [user, loading, error, history, end, start, rtn_url, open]);

  const onFinish = async ({ email, password }) => {
    setStatus('loading');
    await logInWithEmailAndPassword(email, password);
    setStatus('idle');
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="login_left hidden lg:flex lg:flex-col min-h-screen w-1/2 h-full self-stretch pt-20">
          <h1 className="w-full text-center text-5xl  font-thin my-4 text-primary-color">
            WELCOME
          </h1>
          <h2 className="w-full text-center text-2xl font-thin my-4 text-primary-color">
            TO
          </h2>
          <h1 className="w-full text-center text-5xl font-thin my-4 text-primary-color">
            MRARA
          </h1>
        </div>
        <div className="flex-col mt-2 lg:mt-0 lg:w-1/2 w-full items-center justify-center p-2 lg:p-8 pt-20">
          <div className="shadow-sm max-w-xl p-8 flex-col mx-auto bg-white rounded-3xl">
            <p className="w-full text-center text-3xl font-semibold bg-white max-w-xl mx-auto mb-6 text-gray-700">
              Sign In
            </p>

            <Form
              name="normal_login"
              // className="shadow-sm max-w-xl p-8 flex-col mx-auto"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              {status === 'rejected' && stateError && (
                <span className="inline-block w-full p-2 text-center py-1 mb-4 bg-red-300 text-red-900 font-bold rounded-sm">
                  {stateError}
                </span>
              )}
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              >
                <Input
                  type="email"
                  size="large"
                  className="rounded-3xl"
                  prefix={<i className="fal fa-envelope mr-2 font-semibold" />}
                  placeholder="email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Password!',
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  className="rounded-3xl"
                  prefix={<i className="fal fa-lock mr-2 font-semibold" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              {/* <Form.Item> */}

              <div className="flex justify-between pl-2">
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
                <Link
                  to="/password_reset_request"
                  className="text-md text-primary-color hover:text-primary-hover-color mx-1 font-semibold hover:underline pt-1"
                >
                  Forgot Password?
                </Link>
              </div>

              <Form.Item>
                <Button
                  size="large"
                  htmlType="submit"
                  type="primary"
                  className="rounded-3xl w-full mb-2 text-white font-semibold text-gray-700"
                  loading={status === 'loading'}
                >
                  Sign In
                </Button>
              </Form.Item>

              <Form.Item>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full"
                  href={`${process.env.REACT_APP_API_URL}/auth/google`}
                >
                  <Button
                    size="large"
                    onClick={(e) => {
                      e.preventDefault();
                      signInWithGoogle();
                    }}
                    icon={<FcGoogle className="text-xl mr-2" />}
                    type="secondary"
                    className="rounded-3xl login-form-button w-full text-md mb-2 bg-white text-black border border-gray-200 flex justify-center border-none items-center hover:shadow-lg focus:shadow-lg hover:text-black focus:text-black shadow-sm"
                  >
                    Sign In With Google
                  </Button>
                </a>
              </Form.Item>
              <div className="flex justify-between">
                <div>
                  Or{' '}
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      if (rtn_url) {
                        history.push({
                          pathname: '/sign_up',
                          search: `?rtn_url=${rtn_url}&start=${start}&end=${end}`,
                        });
                      } else {
                        history.push('/sign_up');
                      }
                    }}
                    className="text-md text-black hover:text-gray-800 mx-1 font-semibold hover:underline"
                  >
                    Register Now!
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <AddPhNumModal open={open} setOpen={setOpen} setUser={setUser} />
    </>
  );
}

export default LoginForm;
