import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, Upload, Select, Button, message, Spin } from 'antd';
import { getBase64 } from '../../shared/utils/getBase64';
import { BsFileEarmarkPlus, BsPinMap } from 'react-icons/bs';
import { MdOutlineChair } from 'react-icons/md';
import { GiCarDoor } from 'react-icons/gi';
import { FiDollarSign } from 'react-icons/fi';
import { Form, Formik, Field } from 'formik';
import { editCar, uploadCarImage } from '../../db/firebase';
import { nanoid } from 'nanoid';

const { Option } = Select;
const { TextArea } = Input;

function EditCar({ visible, setVisible, refresh, car }) {
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    let imageList = car?.images?.map((image, index) => {
      return {
        name: `name ${index}`,
        status: 'done',
        uid: nanoid(),
        url: image,
      };
    });
    setFileList(imageList);
    // eslint-disable-next-line
  }, []);

  const initialValues = {
    name: car?.name,
    make: car?.make,
    vin: car?.vin,
    mileage: car?.mileage,
    pricePerDay: car?.pricePerDay,
    insurancePerDay: car?.insurancePerDay,
    securityDeposit: car?.securityDeposit,
    doors: car?.doors,
    seats: car?.seats,
    carType: car?.carType,
    key: car?.key,
    pickUpAndReturnLocation: car?.pickUpAndReturnLocation,
    description: car?.description,
    features: car?.features,
    availableDays: car?.availableDays || [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
      'SUNDAY',
    ],
  };

  useEffect(() => {
    if (files && files.length > 0) {
      if (files.length > fileList.length)
        uploadImage(files[files.length - 1], files.length - 1);
      else {
        let lFileList = fileList.filter((fl) => {
          let contains = false;
          files.forEach((f) => {
            if (f.uid === fl.uid) contains = true;
          });
          return contains;
        });

        setFileList(lFileList);
      }
    }
    // eslint-disable-next-line
  }, [files]);

  const validate = (values) => {
    const errors = {};

    if (!values.name) errors.name = 'Required';
    if (!values.make) errors.make = 'Required';
    if (!values.vin) errors.vin = 'Required';
    if (!values.mileage) errors.mileage = 'Required';
    if (!values.pricePerDay) errors.pricePerDay = 'Required';
    if (values.insurancePerDay === '') errors.insurancePerDay = 'Required';
    if (!values.securityDeposit) errors.securityDeposit = 'Required';
    if (!values.doors) errors.doors = 'Required';
    if (!values.seats) errors.seats = 'Required';
    if (!values.carType) errors.carType = 'Required';
    if (!values.key) errors.key = 'Required';
    if (!values.pickUpAndReturnLocation)
      errors.pickUpAndReturnLocation = 'Required';
    if (!values.description) errors.description = 'Required';

    return errors;
  };

  const onSubmit = async (values) => {
    let images = fileList.map((fl) => fl.url);

    let pCarValue = { ...car, ...values, images, features: [] };
    delete pCarValue.id;
    setLoading(true);

    try {
      await editCar(pCarValue, car?.id);
      message.success('Successfully Updated Car!');
      refresh();
      setVisible(false);
    } catch (err) {
      setError('Server error try again later.');
    }
    setLoading(false);
  };

  const handleOk = () => {
    // console.log('on handle cancel');
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const uploadButton = (
    <div className="flex-col items-center justify-center">
      <BsFileEarmarkPlus className="text-2xl mx-auto" />
      <div style={{ marginTop: 8 }}>Add Image</div>
    </div>
  );

  const handlePreview = async (file, index) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };

  const handleChange = ({ fileList }) => {
    setFiles([...fileList]);
  };

  const uploadImage = async (file, index) => {
    let fFileList = [
      ...fileList,
      {
        uid: `${index}`,
        percent: 0,
        name: `image ${index + 1}`,
        status: 'uploading',
        url: '',
      },
    ];
    let res = await uploadCarImage(file.originFileObj).catch((err) =>
      console.log(err),
    );

    if (res) {
      let lFileList = fFileList.map((fl, i) => {
        if (i === index)
          return {
            name: fl.name,
            uid: fl.uid,
            status: 'done',
            url: res,
          };
        return fl;
      });
      setFileList(lFileList);
    }
  };

  return (
    <div>
      <Modal
        title="Edit Car"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="lg:min-w-8/10 min-w-full p-4 top-4"
      >
        <div className="flex-col border-2 border-gray-300 border-dashed p-4">
          <h4 className="text-lg">Edit Car Images</h4>
          <Upload
            showUploadList={{ showPreviewIcon: false }}
            className="flex flex-wrap"
            // accept="image/*"
            onRemove={(file) => {
              setFileList(fileList.filter((f) => f !== file.url));
            }}
            action={() => {}}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {uploadButton}
          </Upload>
        </div>
        <div>
          <Formik
            enableReinitialize={true}
            validate={validate}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ errors, values, touched, setValues }) => (
              <Form>
                <div className="my-2 flex flex-col lg:flex-row md:flex-row">
                  <Field name="name">
                    {({ field }) => (
                      <div className="flex-col">
                        <Input
                          {...field}
                          size="large"
                          id="name"
                          name="name"
                          type="text"
                          placeholder="Enter Car Name"
                        />
                        <div className="text-red-500">
                          {touched.name &&
                            errors.name &&
                            'Car name is required'}
                        </div>
                      </div>
                    )}
                  </Field>
                  <Field name="make">
                    {({ field }) => (
                      <div className="flex-col">
                        <Input
                          {...field}
                          size="large"
                          id="make"
                          name="make"
                          type="text"
                          placeholder="Car Make"
                        />
                        <div className="text-red-500">
                          {touched.make &&
                            errors.make &&
                            'Car make is required'}
                        </div>
                      </div>
                    )}
                  </Field>
                  <Field name="vin">
                    {({ field }) => (
                      <div className="flex-col">
                        <Input
                          {...field}
                          size="large"
                          id="vin"
                          name="vin"
                          type="text"
                          placeholder="VIN"
                        />
                        <div className="text-red-500">
                          {touched.vin && errors.vin && 'VIN is required'}
                        </div>
                      </div>
                    )}
                  </Field>

                  <Field name="mileage">
                    {({ field }) => (
                      <div className="flex-col">
                        <Input
                          {...field}
                          size="large"
                          id="mileage"
                          name="mileage"
                          type="number"
                          placeholder="Mileage"
                        />
                        <div className="text-red-500">
                          {touched.mileage &&
                            errors.mileage &&
                            'Mileage is required'}
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
                <div className="flex flex-col md:flex-row">
                  <Field name="pricePerDay">
                    {({ field }) => (
                      <div className="flex-col">
                        <Input
                          className="mr-2"
                          {...field}
                          size="large"
                          id="pricePerDay"
                          name="pricePerDay"
                          type="number"
                          placeholder="Price/Day"
                          prefix={<FiDollarSign />}
                        />
                        <div className="text-red-500">
                          {touched.pricePerDay &&
                            errors.pricePerDay &&
                            'Price per day is required'}
                        </div>
                      </div>
                    )}
                  </Field>
                  <Field name="insurancePerDay">
                    {({ field }) => (
                      <div className="flex-col">
                        <Input
                          className="mr-2"
                          {...field}
                          size="large"
                          id="insurancePerDay"
                          name="insurancePerDay"
                          type="number"
                          placeholder="Insurance/Day"
                          prefix={<FiDollarSign />}
                        />
                        <div className="text-red-500">
                          {touched.insurancePerDay &&
                            errors.insurancePerDay &&
                            'Insurance price per day is required'}
                        </div>
                      </div>
                    )}
                  </Field>
                  <Field name="securityDeposit">
                    {({ field }) => (
                      <div className="flex-col">
                        <Input
                          className="mr-2"
                          {...field}
                          size="large"
                          id="securityDeposit"
                          name="securityDeposit"
                          type="number"
                          placeholder="Security Deposit"
                          prefix={<FiDollarSign />}
                        />
                        <div className="text-red-500">
                          {touched.securityDeposit &&
                            errors.securityDeposit &&
                            'Security Deposit is required'}
                        </div>
                      </div>
                    )}
                  </Field>
                  <Field name="doors">
                    {({ field }) => (
                      <div className="flex-col">
                        <Input
                          className="mr-2"
                          {...field}
                          size="large"
                          id="doors"
                          name="doors"
                          type="number"
                          placeholder="Number of Doors"
                          suffix={<GiCarDoor />}
                        />
                        <div className="text-red-500">
                          {touched.doors &&
                            errors.doors &&
                            'Number of doors is required'}
                        </div>
                      </div>
                    )}
                  </Field>
                  <Field name="seats">
                    {({ field }) => (
                      <div className="flex-col">
                        <Input
                          {...field}
                          size="large"
                          id="seats"
                          name="seats"
                          type="number"
                          placeholder="Number of Seats"
                          suffix={<MdOutlineChair />}
                        />
                        <div className="text-red-500">
                          {touched.seats &&
                            errors.seats &&
                            'Number of seats is required'}
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
                <div className="my-2">
                  <Field name="description">
                    {({ field }) => (
                      <div className="flex-col">
                        <TextArea
                          {...field}
                          id="description"
                          name="description"
                          size="large"
                          rows={4}
                          placeholder="Car Description"
                        />
                        <div className="text-red-500">
                          {touched.description &&
                            errors.description &&
                            'Description is required'}
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
                <div className="flex flex-col lg:flex-row gap-2 my-2">
                  <Field name="carType">
                    {({ field }) => (
                      <div className="flex-col">
                        <Select
                          className="w-full lg:mr-2 lg:w-64"
                          {...field}
                          onChange={(value) => {
                            setValues({ ...values, carType: value });
                          }}
                          defaultValue=""
                          size="large"
                        >
                          <Option value="">Select Car Type</Option>
                          <Option value="GAS">Gas</Option>
                          <Option value="ELECTRIC">Electric</Option>
                          <Option value="HYBRID">Hybrid</Option>
                        </Select>
                        <div className="text-red-500">
                          {touched.carType &&
                            errors.carType &&
                            'Car type is required'}
                        </div>
                      </div>
                    )}
                  </Field>

                  <Field name="pickUpAndReturnLocation">
                    {({ field }) => (
                      <div className="flex-col lg:mr-2">
                        <Input
                          {...field}
                          size="large"
                          id="pickUpAndReturnLocation"
                          name="pickUpAndReturnLocation"
                          type="text"
                          placeholder="Enter Pick Up Location"
                          suffix={<BsPinMap />}
                        />
                        <div className="text-red-500">
                          {touched.pickUpAndReturnLocation &&
                            errors.pickUpAndReturnLocation &&
                            'Pick up and return location is required'}
                        </div>
                      </div>
                    )}
                  </Field>
                  <Field name="key">
                    {({ field }) => (
                      <div className="flex-col">
                        <Input
                          {...field}
                          size="large"
                          id="key"
                          name="key"
                          type="text"
                          placeholder="Enter a key to identify this car"
                        />
                        <div className="text-red-500">
                          {touched.key && errors.key && 'Key is required'}
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
                {/* <div className="flex-col border-2 border-gray-300 border-dashed p-2 mb-4">
                  <FieldArray name="availableDays">
                    <div className="w-full">
                      <h4 className="text-lg">Available Days</h4>
                      {renderAvailableDays(values, setValues)}
                    </div>
                  </FieldArray>
                </div> */}
                {error && (
                  <p className="text-red-700 w-full text-center my-2">
                    {error}
                  </p>
                )}
                {!loading && (
                  <Button
                    className="bg-primary-color hover:bg-primary-hover-color text-white hover:border-primary-color w-full"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
                {loading && <Spin />}
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
}

EditCar.defaultProps = {
  visible: false,
};

EditCar.propTypes = {
  visible: PropTypes.bool,
  refresh: PropTypes.func,
  setVisible: PropTypes.func,
};

export default EditCar;
