import React, { useState, useEffect, useContext } from 'react';
import { notification as toast } from 'antd';
import { onMessageListener, requestPermission } from '../../db/firebase';
import UserContext from '../context/UserContext';

const Notification = ({ user }) => {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [api, contextHolder] = toast.useNotification();

  useEffect(() => {
    if (user) {
      requestPermission(user?.uid);
    }
  }, [user]);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log('failed: ', err));

  useEffect(() => {
    if (notification?.title) {
      api.open({
        message: notification.title,
        description: notification.body,
      });
    }
  }, [notification, api]);

  return <>{contextHolder}</>;
};

export default Notification;
