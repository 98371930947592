import React, { Suspense, useEffect } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import UserContext from './shared/context/UserContext';

// Preloader
// import Preloader from './sharedcomponents/Preloader';

// Roles
// const Anonymous = React.lazy(() => import('./anonymous/Anonymous'));
// const AuthUser = React.lazy(() => import('./auth-user/AuthUser'));
import Anonymous from './anonymous/Anonymous';
import AuthUser from './auth-user/AuthUser';
// import UserGuard from './shared/auth/UserGuard';
import Admin from './admin/Admin';
import useLocalStorage from './shared/hooks/useLocalStorage';
import Header from './shared/components/Header';
import Notification from './shared/components/PushNotification';

function App() {
  const [user, setUser] = useLocalStorage('user', null);

  return (
    <div className="flex flex-col min-h-screen justify-between">
      {user && <Notification user={user} />}
      <Router>
        <UserContext.Provider value={{ user, setUser }}>
          <Header phoneNumber="+1 832 861 6479" />
          <Suspense fallback={<div></div>}>
            {/* <Preloader /> */}
            <Switch>
              <Route path="/admin" component={Admin} />
              <Route path="/u" component={AuthUser} />
              <Route path="/" component={Anonymous} />
            </Switch>
          </Suspense>
        </UserContext.Provider>
      </Router>
    </div>
  );
}

export default App;
