import React, { useContext, useState, useEffect } from 'react';
import { Card, Skeleton, Spin, Tabs } from 'antd';
import BookedRevs from '../components/BookedRevs';
import EndedRevs from '../components/EndedRevs';
import UserContext from '../../shared/context/UserContext';
import { getAllCarReservations, getCarReservations } from '../../db/firebase';

export default function HomeTest() {
  const { user } = useContext(UserContext);
  const [reservations, setReservations] = useState([]);
  const [endedRevs, setEndedRevs] = useState([]);
  const [activeTab, setActiveTab] = useState('booked');
  const [loading, setLoading] = useState(true);
  const isAdmin = user?.roles?.includes('admin');
  const [allRevs, setAllRevs] = useState([]);

  const fetchData = async () => {
    let reservationsData;
    if (isAdmin) {
      reservationsData = await getAllCarReservations();
    } else {
      reservationsData = await getCarReservations(user);
    }

    if (!reservationsData) {
      setReservations([]);
      setEndedRevs([]);
      setLoading(false);
      return;
    }
    setAllRevs(reservationsData);

    const ended = [];
    const active = [];
    reservationsData.map((r) => {
      if (r.status !== 'Trip Ended') {
        active.push(r);
      } else {
        ended.push(r);
      }
    });
    setReservations(active);
    setEndedRevs(ended);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   fetchData().then((res) => {
  //     if (!res) {
  //       setReservations([]);
  //       setEndedRevs([]);
  //       setLoading(false);
  //       return;
  //     }
  //     const ended = [];
  //     const active = [];
  //     res.map((r) => {
  //       if (r.status !== 'Trip Ended') {
  //         active.push(r);
  //       } else {
  //         ended.push(r);
  //       }
  //     });
  //     setReservations(active);
  //     setEndedRevs(ended);
  //     setLoading(false);
  //   });
  // }, []);

  // const fetchData = async () => {
  //   if (isAdmin) {
  //     return await getAllCarReservations();
  //   } else {
  //     return await getCarReservations(user);
  //   }
  // };

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const items = [
    {
      key: 'booked',
      label: <p className="font-bold text-md">Booked</p>,
      children: (
        <BookedRevs
          fetchData={fetchData}
          revs={reservations}
          allRevs={allRevs}
        />
      ),
    },
    {
      key: 'history',
      label: <p className="font-bold text-md">History</p>,
      children: <EndedRevs revs={endedRevs} allRevs={allRevs} />,
    },
  ];

  return (
    <Card className="min-h-screen">
      {/* {loading ? (
        <Spin />
      ) : ( */}
      <Tabs
        defaultActiveKey="booked"
        className="md:pl-8 "
        onChange={handleChangeTab}
      >
        {items.map((item) => (
          <Tabs.TabPane tab={item.label} key={item.key}>
            <Skeleton loading={loading} active>
              {item.children}
            </Skeleton>
            {/* {loading ? <Spin /> : item.children} */}
          </Tabs.TabPane>
        ))}
      </Tabs>
      {/* )} */}
    </Card>
  );
}
