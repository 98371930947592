import React, { Fragment, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Card,
  Button,
  Table,
  Spin,
  Popconfirm,
  message,
  Pagination,
  Switch,
  Input,
} from 'antd';
import moment from 'moment';
import AddCar from '../modals/AddCar';
import { BsPlusCircleDotted, BsTrash } from 'react-icons/bs';
import EditCar from '../modals/EditCar';
import { useHistory } from 'react-router-dom';
import { editCar, getDocuments, deleteCarById } from '../../db/firebase';
import EditDates from '../modals/EditDates';
import { SearchOutlined } from '@ant-design/icons';

const Home = () => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [addCarVisible, setAddCarVisible] = useState(false);
  const [editCarVisible, setEditCarVisible] = useState(false);
  const [currentCar, setCurrentCar] = useState({});
  const [editDatesVisible, setEditDatesVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [btnClicked, setBtnClicked] = useState('');
  const [updatedRow, setUpdatedRow] = useState('');
  const [originalCars, setOriginalCars] = useState([]);
  const [value, setValue] = useState('');
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const [current, setCurrent] = useState(params.get('current') || 1);
  const [pageSize, setPageSize] = useState(params.get('pageSize') || 10);

  useEffect(() => {
    fetchCars();
    history.push({
      pathname: '/admin',
      search: `?current=${current}&pageSize=${pageSize}`,
    });
  }, [current, pageSize]);

  const columns = [
    {
      title: 'Image',
      render: (value) => {
        return (
          <img src={value.images[0]} alt="Img" className="h-10 rounded-lg" />
        );
      },
      key: 'image',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Price Per Day',
      dataIndex: 'pricePerDay',
      key: 'pricePerDay',
    },
    {
      title: 'Insurance Per Day',
      dataIndex: 'insurancePerDay',
      key: 'insurancePerDay',
    },
    {
      title: 'Doors',
      dataIndex: 'doors',
      key: 'doors',
    },
    {
      title: 'Seats',
      dataIndex: 'seats',
      key: 'seats',
    },
    {
      title: 'Car Type',
      dataIndex: 'carType',
      key: 'carType',
    },
    {
      title: 'Location',
      dataIndex: 'pickUpAndReturnLocation',
      key: 'pickUpAndReturnLocation',
    },
    // {
    //   title: 'Created At',
    //   key: 'createdAt',
    //   render: (value) => {
    //     return moment(new Date(value.createdAt?.seconds * 1000)).format(
    //       'DD-MM-YYYY',
    //     );
    //   },
    // },
    {
      title: 'Dates',
      key: 'dates',
      render: (value, index) => {
        return (
          <>
            <Button
              size="small"
              className="mb-2 rounded-lg"
              onClick={() => {
                setEditDatesVisible(true);
                setCurrentCar(value);
                setBtnClicked('enable');
              }}
            >
              Enable Dates
            </Button>
            <Button
              size="small"
              onClick={() => {
                setEditDatesVisible(true);
                setCurrentCar(value);
                setBtnClicked('disable');
              }}
              className="rounded-lg"
            >
              Disable Dates
            </Button>
          </>
        );
      },
    },
    {
      title: '',
      render: (value) => {
        return (
          <Switch
            key={value?.name}
            checkedChildren={
              <p
                className="font-semibold"
                style={{ fontSize: '10px', paddingRight: '10px' }}
              >
                Listed
              </p>
            }
            unCheckedChildren={
              <p
                className="text-xs font-semibold text-black"
                style={{ fontSize: '10px' }}
              >
                Unlisted
              </p>
            }
            onChange={(checked) => listUnlistCar(checked, value)}
            defaultChecked={value?.status === 'ACTIVE'}
            loading={updatedRow === value?.id}
          />
        );
      },
      key: 'action-edit',
    },
    {
      title: '',
      render: (value) => {
        return (
          <Button
            onClick={() => {
              setCurrentCar(value);
              setEditCarVisible(true);
            }}
            className="rounded-lg"
          >
            Edit
          </Button>
        );
      },
      key: 'action-edit',
    },
    {
      title: '',
      render: (value) => {
        return (
          <Popconfirm
            title="Are you sure to delete this car?"
            onConfirm={() => deleteCar(value)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button danger type="text">
              <BsTrash />
            </Button>
          </Popconfirm>
        );
      },
      key: 'action-delete',
    },
  ];

  const fetchCars = async () => {
    setLoading(true);
    let res = await getDocuments('cars');
    if (res) {
      setCars(res);
      setOriginalCars(res);
      setTotal(res.length);
    } else {
      console.log(res);
      setError('Server error try later.');
    }
    setLoading(false);
  };

  const refreshPageOnChange = () => {
    fetchCars();
  };

  const deleteCar = async (value) => {
    try {
      await deleteCarById(value?.id);
      message.success('Successfully deleted car');
      await fetchCars();
    } catch (err) {
      message.error('Something went wrong, try again later.');
      setError('Server error try again later.');
    }
  };

  const listUnlistCar = async (checked, rowData) => {
    try {
      setUpdatedRow(rowData.id);
      const status = checked ? 'ACTIVE' : 'INACTIVE';
      await editCar({ status }, rowData?.id);
      message.success(
        `Successfully ${status === 'ACTIVE' ? 'listed' : 'unlisted'} car`,
      );
      setCars((prevCars) =>
        prevCars.map((car) =>
          car.id === rowData.id ? { ...car, status } : car,
        ),
      );
      setUpdatedRow('');
    } catch (err) {
      message.error('Something went wrong, try again later.');
      setError('Server error try again later.');
    }
  };

  const handleSearch = (e) => {
    const currValue = e.target.value;
    setValue(currValue);
    if (currValue === '') {
      setCars(originalCars);
    } else {
      const filteredData = originalCars.filter((entry) =>
        entry?.name?.toLowerCase().includes(currValue.toLowerCase()),
      );
      setCars(filteredData);
    }
  };

  return (
    <Fragment>
      <MetaTags>
        <title>Mrara Enterprises | Admin</title>
        <meta name="description" content="#" />
      </MetaTags>
      <section className="mt-24" style={{ minHeight: '20rem' }}>
        {!loading && !error && (
          <>
            <div className="flex flex-col md:flex-row md:items-center px-6 py-2 justify-between w-full md:w-11/12 mx-auto px-2">
              <h1 className=" text-4xl">Your Cars</h1>
              <div className="flex justify-end gap-4 w-full md:w-1/2 mt-4">
                <Button
                  className="flex items-center rounded-lg"
                  onClick={() => setAddCarVisible(true)}
                  size="large"
                >
                  Add <BsPlusCircleDotted className="text-lg ml-2" />
                </Button>
                <Input
                  placeholder="Search Car"
                  value={value}
                  onChange={handleSearch}
                  className="w-full md:w-1/2 mb-4 shadow-sm rounded-lg"
                  prefix={<SearchOutlined />}
                  size="large"
                />
              </div>
            </div>
            {/* <Card> */}
            <div className="flex flex-col items-end w-full md:w-11/12 mx-auto px-2">
              <Table
                className="min-w-sm w-full overflow-x-auto rounded-xl shadow-lg"
                pagination={false}
                dataSource={cars.slice(
                  (current - 1) * pageSize,
                  (current - 1) * pageSize + pageSize,
                )}
                columns={columns}
              />
              {!value && (
                <Pagination
                  onChange={(newPage, newPageSize) => {
                    setCurrent(newPage);
                    setPageSize(newPageSize);
                  }}
                  className="my-4 mr-2"
                  current={current}
                  defaultCurrent={current}
                  pageSize={pageSize}
                  total={total}
                  pageSizeOptions={['2', '5', '10', '15']}
                  showTotal={() => `Total ${total} Items`}
                  showSizeChanger
                />
              )}
            </div>
            {/* </Card> */}
          </>
        )}
        {loading && (
          <div className="w-full mt-32 flex justify-center">
            <Spin size="large" />
          </div>
        )}
        {error && <p className="text-center text-lg text-gray-700">{error}</p>}
      </section>

      {addCarVisible && (
        <AddCar
          visible={addCarVisible}
          setVisible={setAddCarVisible}
          refresh={refreshPageOnChange}
        />
      )}
      {editCarVisible && (
        <EditCar
          visible={editCarVisible}
          setVisible={setEditCarVisible}
          refresh={refreshPageOnChange}
          car={currentCar}
        />
      )}
      {editDatesVisible && (
        <EditDates
          visible={editDatesVisible}
          setVisible={setEditDatesVisible}
          refresh={refreshPageOnChange}
          car={currentCar}
          btnClicked={btnClicked}
        />
      )}
    </Fragment>
  );
};

export default Home;
