import getParams from '../utils/getParams';
import axios from './axiosDefault';

const API_URL = process.env.REACT_APP_API_URL;
export const login = (obj) => {
  return axios.post(API_URL + '/auth/login', obj);
};

export const googleSignIn = () => {
  return axios.get(`${API_URL}/auth/google`);
};

export const signUp = (obj) => {
  return axios.post(API_URL + '/users', obj);
};

export const getUsers = (params) => {
  let urlParams = getParams(params);
  return axios.get(`${API_URL}/users?${urlParams}`);
};

export const passwordResetRequest = (obj) => {
  return axios.post(API_URL + '/auth/forgot_password', obj);
};

export const resetPassword = (obj) => {
  return axios.post(API_URL + '/auth/reset_password', obj);
};
