import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Dropdown, message, Spin } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import {
  subscribeToAdminNotifications,
  markNotificationsAsRead,
  subscribeToUserNotifications,
} from '../../db/firebase';
import UserContext from '../../shared/context/UserContext';
import moment from 'moment';
import { BellOutlined, UserOutlined } from '@ant-design/icons';

const Notification = () => {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasUnread, setHasUnread] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isAdmin = user?.roles?.includes('admin');

  useEffect(() => {
    let unsubscribe;

    if (isAdmin) {
      unsubscribe = subscribeToAdminNotifications((newNotifications) => {
        const sortedNotifications = newNotifications.sort((a, b) => {
          if (a.created.seconds !== b.created.seconds) {
            return b.created.seconds - a.created.seconds;
          }
          return b.created.nanoseconds - a.created.nanoseconds;
        });

        setNotifications(sortedNotifications);
        setHasUnread(
          sortedNotifications.some((notification) => !notification.isRead),
        );
        setLoading(false);
      });
    } else if (user?.uid) {
      unsubscribe = subscribeToUserNotifications(
        user.uid,
        (newNotifications) => {
          const sortedNotifications = newNotifications.sort((a, b) => {
            if (a.created.seconds !== b.created.seconds) {
              return b.created.seconds - a.created.seconds;
            }
            return b.created.nanoseconds - a.created.nanoseconds;
          });

          setNotifications(sortedNotifications);
          setHasUnread(
            sortedNotifications.some((notification) => !notification.isRead),
          );
          setLoading(false);
        },
      );
    }

    return () => unsubscribe && unsubscribe();
  }, [user]);

  const getTimeDifference = (timestamp) => {
    const time = moment
      .unix(timestamp.seconds)
      .add(timestamp.nanoseconds / 1e6, 'milliseconds');
    const now = moment();
    const duration = moment.duration(now.diff(time));

    const pluralize = (unit, value) => {
      return `${Math.floor(value)} ${unit}${
        Math.floor(value) !== 1 ? 's' : ''
      } ago`;
    };

    if (duration.asMinutes() < 60) {
      return pluralize('minute', duration.asMinutes());
    } else if (duration.asHours() < 24) {
      return pluralize('hour', duration.asHours());
    } else if (duration.asDays() < 7) {
      return pluralize('day', duration.asDays());
    } else if (duration.asWeeks() < 4) {
      return pluralize('week', duration.asWeeks());
    } else {
      return pluralize('month', duration.asMonths());
    }
  };

  const handleIconClick = async () => {
    setIsDropdownOpen(true);

    const unreadNotificationIds = notifications
      .filter((notification) => !notification.isRead)
      .map((notification) => notification.id);

    if (unreadNotificationIds.length > 0) {
      try {
        await markNotificationsAsRead(
          unreadNotificationIds,
          user?.uid,
          isAdmin,
        );
        setHasUnread(false);
      } catch (error) {
        message.error('Failed to mark notifications as read');
      }
    }
  };

  const notificationList = useMemo(
    () => (
      <div className="">
        <ul className="bg-white shadow-lg rounded-lg w-96 dropdown overflow-y-auto">
          <li className="sticky top-0 bg-white z-10 border-b border-gray-300">
            <p className="text-lg font-semibold px-2 text-gray-700">
              Notifications
            </p>
          </li>
          {loading ? (
            <Spin size="small" className="mx-auto" />
          ) : notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <li
                key={notification.id}
                className={`min-h-40 px-2 py-2 hover:bg-gray-100 ${
                  index !== notifications.length - 1 &&
                  'border-b border-gray-200'
                }`}
              >
                {isAdmin ? (
                  <Link
                    to={notification.link ? notification.link : '#'}
                    className="flex gap-3 hover:text-black"
                  >
                    <div>
                      <UserOutlined className="text-lg" />
                    </div>

                    <div>
                      {notification?.body}
                      <p className="text-sm text-gray-500 mt-2">
                        {getTimeDifference(notification.created)}
                      </p>
                    </div>
                  </Link>
                ) : (
                  <div className="flex gap-3">
                    <div>
                      <UserOutlined className="text-lg" />
                    </div>

                    <div>
                      {notification?.body}
                      <p className="text-sm text-gray-500 mt-2">
                        {getTimeDifference(notification.created)}
                      </p>
                    </div>
                  </div>
                )}
              </li>
            ))
          ) : (
            <li className="px-2 py-2 text-center text-gray-500">
              No notifications
            </li>
          )}
        </ul>
      </div>
    ),
    [notifications, loading],
  );

  return (
    <>
      <Dropdown
        overlay={notificationList}
        trigger={['click']}
        onClick={handleIconClick}
        className="cursor-pointer"
        onVisibleChange={(open) => setIsDropdownOpen(open)}
      >
        <div className="relative">
          <BellOutlined
            className={`text-2xl lg:text-xl ${
              isDropdownOpen ? 'text-primary-color' : 'text-black'
            }`}
          />
          {hasUnread && (
            <span className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full" />
          )}
        </div>
      </Dropdown>
    </>
  );
};

export default React.memo(Notification);
