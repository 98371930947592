import React, { useState } from 'react';
import { Button, DatePicker, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import {
  getCarReservationsByCarId,
  disableDatesForCar,
  enableDatesForCar,
} from '../../db/firebase';
import moment from 'moment';

const { RangePicker } = DatePicker;

const EditDates = ({ visible, setVisible, refresh, car, btnClicked }) => {
  const [dates, setDates] = useState({
    start: null,
    end: null,
  });
  const [reservations, setReservations] = useState([]);
  const [disabledDates, setDisabledDates] = useState([]);
  const [status, setStatus] = useState('idle');

  const handleCancel = () => {
    setVisible(false);
  };

  const handleDateSelect = (value) => {
    if (value) {
      setDates({
        start: moment(value[0]).format('YYYY-MM-DD'),
        end: moment(value[1]).format('YYYY-MM-DD'),
      });
    } else {
      setDates({
        start: null,
        end: null,
      });
    }
  };

  const fetchReservationById = async () => {
    let res = await getCarReservationsByCarId(car?.carId).catch((err) =>
      console.log(err),
    );
    if (res) {
      setReservations(res);
      setDisabledDates(car?.disabledDates);
    }
  };

  const handleDisable = async () => {
    try {
      setStatus('loading');
      if (btnClicked === 'enable') {
        await enableDatesForCar(dates, car?.id).then(() => {
          message.success('Dates Enabled!');
          refresh();
          setVisible(false);
        });
      } else {
        await disableDatesForCar(dates, car?.id).then(() => {
          message.success('Dates Disabled!');
          refresh();
          setVisible(false);
        });
      }
    } catch (err) {
      console.log(err);
      refresh();
      setVisible(false);
      return message.warning('Something went wrong, please try again!');
    }
  };

  const disabledDate = (current) => {
    const disabled =
      current &&
      (reservations.some(
        (reservation) =>
          reservation.car === car?.carId &&
          moment(reservation.start).isSameOrBefore(current, 'day') &&
          moment(reservation.end).isSameOrAfter(current, 'day'),
      ) ||
        disabledDates?.some(
          (date) =>
            moment(date.start).isSameOrBefore(current, 'day') &&
            moment(date.end).isSameOrAfter(current, 'day'),
        ));
    if (btnClicked === 'enable') {
      return current < moment().subtract(1, 'days') || !disabled;
    }
    return current < moment().subtract(1, 'days') || disabled;
  };

  return (
    <>
      <Modal
        title={
          btnClicked === 'enable'
            ? 'Select Dates to Enable'
            : 'Select Dates to Disable'
        }
        visible={visible}
        onOk={handleDisable}
        onCancel={handleCancel}
        footer={null}
        className="flex flex-col"
      >
        <div className="flex justify-center">
          <RangePicker
            allowClear
            dropdownClassName="createDateRangePicker"
            size="large"
            placeholder={['Start', 'End']}
            className="rounded-md text-gray-600 mb-3"
            onChange={handleDateSelect}
            onFocus={fetchReservationById}
            autoFocus
            disabledDate={(current) => disabledDate(current)}
            dateRender={(current) => {
              const isDisabled = disabledDate(current);
              return (
                <div
                  className={
                    isDisabled
                      ? `line-through ${
                          current.isSame(moment(), 'day')
                            ? 'current-date-disabled'
                            : ''
                        }`
                      : 'ant-picker-cell-inner'
                  }
                >
                  {current.date()}
                </div>
              );
            }}
          />
        </div>
        <div className="flex justify-center gap-3">
          <Button
            onClick={handleCancel}
            className="hover:border-primary-color hover:text-primary-color"
          >
            Cancel
          </Button>
          <Button
            // type="primary"
            className="bg-primary-color hover:bg-primary-hover-color text-white hover:border-primary-color"
            disabled={!dates.start || !dates.end}
            loading={status === 'loading'}
            onClick={handleDisable}
          >
            {btnClicked === 'enable' ? 'Enable' : 'Disable'}
          </Button>
        </div>
      </Modal>
    </>
  );
};
EditDates.defaultProps = {
  visible: false,
};

EditDates.propTypes = {
  visible: PropTypes.bool,
  refresh: PropTypes.func,
  setVisible: PropTypes.func,
};

export default EditDates;
