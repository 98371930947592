import getParams from '../utils/getParams';
import axios from './axiosDefault';

const API_URL = process.env.REACT_APP_API_URL;

export const verifyEmail = (params) => {
  const urlParams = getParams(params);
  return axios.get(`${API_URL}/auth/verify_email?${urlParams}`);
};

export const verifyPhoneNumber = (payload) => {
  return axios.post(`${API_URL}/auth/verify_phone_number`, payload);
};
