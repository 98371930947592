import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';
import UserContext from '../../shared/context/UserContext';

function GoogleAuth(props) {
  const history = useHistory();
  const { setUser } = useContext(UserContext);

  const params = new URLSearchParams(history.location.search);
  useEffect(() => {
    let user = {};
    params.forEach((value, key) => {
      user[key] = value;
    });
    setUser(user);
    setTimeout(() => {
      history.push('/u');
    }, 1000);
  });

  return (
    <div className="flex w-full justify-center py-12">
      <Spin size="large" />
    </div>
  );
}

export default GoogleAuth;
