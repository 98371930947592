import React from 'react';
import MetaTags from 'react-meta-tags';
import LoginForm from '../components/LoginForm';

function Login(props) {
  return (
    <div className="min-h-screen">
      <MetaTags>
        <title>Mrara Enterprises | Sign In</title>
        <meta name="description" content="#" />
      </MetaTags>
      <section id="#login_section">
        <LoginForm />
      </section>
    </div>
  );
}

Login.propTypes = {};

export default Login;
