import React, { useState, useEffect, useContext } from 'react';
import { Modal, Spin, message, Image, Upload, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';
import { getBase64 } from '../../shared/utils/getBase64';
import { deleteImage, uploadTripImage } from '../../db/firebase';
import UserContext from '../../shared/context/UserContext';
import { DeleteFilled } from '@ant-design/icons';

const contentStyle = {
  padding: 50,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,
};
const content = <div style={contentStyle} />;

const PreTripPhotos = ({ visible, onCancel, reservation }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { user } = useContext(UserContext);
  const isAdmin = user?.roles?.includes('admin');

  useEffect(() => {
    let imageList = reservation?.preTripImages?.map((image, index) => {
      return {
        name: `name ${index}`,
        status: 'done',
        uid: nanoid(),
        url: image,
      };
    });
    setFileList(imageList || []);
  }, []);

  useEffect(() => {
    if (files && files?.length > 0) {
      if (files?.length > fileList?.length)
        uploadImage(files[files.length - 1], files.length - 1);
      else {
        let lFileList = fileList?.filter((fl) => {
          let contains = false;
          files.forEach((f) => {
            if (f.uid === fl.uid) contains = true;
          });
          return contains;
        });
        setFileList(lFileList);
      }
    }
  }, [files]);

  const handlePreview = async (file, index) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList }) => {
    setFiles([...fileList]);
  };

  const uploadButton = uploading ? (
    <Spin tip="Uploading" size="small">
      {content}
    </Spin>
  ) : (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
      disabled={uploading || !isAdmin} // Disable button if uploading or not admin
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const uploadImage = async (file, index) => {
    setUploading(true);
    let fFileList = [
      ...fileList,
      {
        uid: `${index}`,
        percent: 0,
        name: `image ${index + 1}`,
        status: 'uploading',
        url: '',
      },
    ];
    setFileList(fFileList);
    let res = await uploadTripImage(
      file.originFileObj,
      reservation,
      'preTripImages',
    ).catch((err) => {
      console.log(err);
      message.error('Failed to upload image, please try again');
    });

    if (res) {
      let lFileList = fFileList.map((fl, i) => {
        if (i === index)
          return {
            name: fl.name,
            uid: fl.uid,
            status: 'done',
            url: res,
          };
        return fl;
      });
      setFileList(lFileList);
      message.success('Image uploaded successfully');
    }
    setUploading(false); // Set uploading state to false after upload completes
  };

  const handleDelete = (file) => {
    setFileList((prev) => prev.filter((f) => f.uid !== file.uid));
    deleteImage(file.url, reservation, 'preTripImages');
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      title="Pre-Trip Photos"
    >
      <Upload
        //   multiple= {true}
        listType="picture-card"
        fileList={fileList}
        onPreview={(file, index) => handlePreview(file, index)}
        onChange={handleChange}
        onRemove={(file) => {
          setFileList((prev) => prev.filter((f) => f.uid !== file.uid));
          deleteImage(file.url, reservation, 'preTripImages');
        }}
        disabled={uploading || !isAdmin}
        showUploadList={false}
      >
        {uploadButton}
        {/* {fileList.length >= 8 ? null : uploadButton} */}
      </Upload>
      <Image.PreviewGroup
        preview={{
          onChange: (current, prev) =>
            console.log(`current index: ${current}, prev index: ${prev}`),
        }}
      >
        <div className="flex flex-wrap">
          {fileList?.map((img) => (
            <div className="relative">
              <Image width={200} height={200} src={img?.url} className="p-3" />
              <Tooltip placement="top" title="Delete">
                <DeleteFilled
                  onClick={() => handleDelete(img)}
                  className="absolute bottom-4 right-3 text-red-600 p-1 bg-red-200 rounded-full hover:bg-red-500 hover:text-white hover:scale-110 cursor-pointer transition-all duration-300 shadow-md hover:shadow-lg"
                />
              </Tooltip>
            </div>
          ))}
        </div>
      </Image.PreviewGroup>
    </Modal>
  );
};

export default PreTripPhotos;
