import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useStripe } from '@stripe/react-stripe-js';
import {
  Button,
  message,
  Spin,
  Carousel,
  Form,
  Divider,
  Tooltip,
  Radio,
} from 'antd';
import moment from 'moment';
import UserContext from '../../shared/context/UserContext';
import { SlLocationPin } from 'react-icons/sl';
import {
  getCar,
  getCarReservationsByCarId,
  getUser,
  logoutFirebase,
  reserveCar,
} from '../../db/firebase';
import AddPhNumModal from '../../auth-user/components/AddPhNumModal';
import { DatePicker, Application } from 'react-rainbow-components';
import { FiCalendar, FiInfo, FiClock } from 'react-icons/fi';

function generateTimeOptions(date, splitdates, type) {
  let options = [];

  if (date == null || date == undefined) {
    options = generateTime(0, 24);
  } else {
    var dateMoment = moment(date);
    var currentTime = moment().add(3, 'hours');
    var currentHour = currentTime.hour();

    const matchedDate = splitdates.find((splitdate) =>
      dateMoment.isSame(moment(splitdate), 'day'),
    );

    if (matchedDate) {
      if (type === 'checkout') {
        var splitDateMoment = moment(matchedDate).subtract(3, 'hours');
        var splitHour = splitDateMoment.hour();
        options = generateTime(0, splitHour);
      } else {
        var splitDateMoment = moment(matchedDate).add(3, 'hours');
        var splitHour = splitDateMoment.hour();
        options = generateTime(splitHour, 24);
      }
    } else if (moment().isSame(dateMoment, 'day')) {
      options = generateTime(currentHour, 24);
    } else {
      options = generateTime(0, 24);
    }
  }

  return options;
}

function generateTime(start, end) {
  let options = [];
  let intervals = [0, 30];
  for (let hour = start; hour < end; hour++) {
    for (let interval of intervals) {
      let hour12 = hour % 12 || 12;
      hour12 = hour12 < 10 ? '0' + hour12 : hour12;
      interval = interval < 10 ? '0' + interval : interval;
      let suffix = hour < 12 ? 'AM' : 'PM';
      let value = hour12 + ':' + interval + ' ' + suffix;
      let label = value;
      if (value === '12:00 AM') {
        label = 'Midnight';
      }
      if (value === '12:00 PM') {
        label = 'Noon';
      }
      let option = { value, label };
      options.push(option);
    }
  }
  return options;
}

function useWindowSize() {
  const [size, setSize] = useState([false]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth < 768]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const theme = {
  rainbow: {
    palette: {
      brand: '#5c56b6',
    },
  },
};

const SearchDetail = () => {
  const [isMobile] = useWindowSize();
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();
  let { id } = useParams();
  const [car, setCar] = useState({});
  const queryParams = new URLSearchParams(history.location.search);
  const [status, setStatus] = useState('idle');
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [dates, setDates] = useState({
    start: null,
    end: null,
  });
  const [open, setOpen] = useState(false);
  const [dbUser, setDbUser] = useState({});
  const [reservations, setReservations] = useState([]);
  const [disabledDates, setDisabledDates] = useState([]);
  const [isBookBtnDisabled, setIsBookBtnDisabled] = useState(true);
  const [isMinDateSelected, setIsMinDateSelected] = useState(true);
  const [paymentType, setPaymentType] = useState('online');

  const [form] = Form.useForm();

  let startDate = queryParams.get('start');
  let endDate = queryParams.get('end');

  const stripe = useStripe();
  const [range, setRange] = useState(undefined);

  const [time, setTime] = useState({
    start: '10:00 AM',
    end: '10:00 AM',
  });
  const [splitStartDates, setSplitStartDates] = useState([]);
  const [splitEndDates, setSplitEndDates] = useState([]);
  const [disabled, setDisabled] = useState([]);

  let options = generateTimeOptions(
    range?.range[0],
    splitStartDates,
    'checkin',
  );
  let checkoutOptions = generateTimeOptions(
    range?.range[1],
    splitEndDates,
    'checkout',
  );
  const [value, setValue] = useState(null);
  const [insuranceFee, setInsuranceFee] = useState(0);
  const initialDailyTotal = numberOfDays * (car?.pricePerDay + insuranceFee);
  const promptText =
    'The security deposit serves as a financial assurance held by the lessor until the lessee returns the rental car. This amount is earmarked to cover unforeseen expenses, including but not limited to damages to the vehicle, toll charges, and parking fines. Any remaining balance from the initial deposit of $200 will be promptly refunded to the lessee upon the satisfactory conclusion of the rental period. This practice ensures a transparent and efficient resolution of any incidental charges incurred during the course of the lease agreement.';

  useEffect(() => {
    if (id) {
      (async () => {
        let res = await getCar(id).catch((err) => console.log(err));
        if (res) {
          setCar(res);
          setInsuranceFee(Number(res?.insurancePerDay?.toFixed(2)));
        }
      })();
      (async () => {
        let res = await getCarReservationsByCarId(id).catch((err) =>
          console.log(err),
        );
        if (res) {
          setReservations(res);
        }
      })();
    }
  }, [id]);

  useEffect(() => {
    setDisabledDates(car?.disabledDates);
  }, [car]);

  useEffect(() => {
    if (disabledDates && reservations) {
      setDisabled(getDisabledDates()[0]);
      setSplitEndDates(getDisabledDates()[1]);
      setSplitStartDates(getDisabledDates()[2]);
    }
  }, [disabledDates, reservations]);

  useEffect(() => {
    if (value) {
      setInsuranceFee(0);
    } else {
      setInsuranceFee(Number(car?.insurancePerDay?.toFixed(2)));
    }
  }, [value]);

  useEffect(() => {
    if (range?.range.length === 2) {
      setDates({
        start: moment(range?.range[0]).format('YYYY-MM-DD'),
        end: moment(range?.range[1]).format('YYYY-MM-DD'),
      });
      if (options.length < 48) {
        setTime((prev) => ({ ...prev, start: options[0].value }));
      }
      if (checkoutOptions.length < 48) {
        setTime((prev) => ({ ...prev, end: checkoutOptions[0].value }));
      }
      setNumberOfDays(
        dayDifference(
          moment(
            moment(range?.range[0]).format('YYYY-MM-DD') +
              ' ' +
              (time.start || '10:00 AM'),
            'YYYY-MM-DD h:mm a',
          ).format('YYYY-MM-DDTHH:mm:ss'),
          moment(
            moment(range?.range[1]).format('YYYY-MM-DD') +
              ' ' +
              (time.end || '10:00 AM'),
            'YYYY-MM-DD h:mm a',
          ).format('YYYY-MM-DDTHH:mm:ss'),
        ),
      );
    }
  }, [range]);

  useEffect(() => {
    if (user) {
      (async () => {
        let res = await getUser(user.uid).catch((err) => console.log(err));
        if (res) {
          setDbUser(res);
        }
      })();
    }
  }, [user]);

  useEffect(() => {
    if (Object.keys(car).length !== 0 && endDate && startDate) {
      const start = startDate.split('T')[0];
      const end = endDate.split('T')[0];
      const startTime = moment(startDate.split('T')[1], 'HH:mm').format(
        'h:mm A',
      );
      const endTime = moment(endDate.split('T')[1], 'HH:mm').format('h:mm A');

      setNumberOfDays(dayDifference(startDate, endDate));
      setDates({
        start: start,
        end: moment(endDate).format('YYYY-MM-DD'),
      });
      options = generateTimeOptions(
        range?.range[0],
        splitStartDates,
        'checkin',
      );
      checkoutOptions = generateTimeOptions(
        range?.range[1],
        splitEndDates,
        'checkout',
      );
      setTime({
        start: startTime,
        end: endTime,
      });
      form.setFieldsValue({
        dates_rainbow: [new Date(start), new Date(end)],
        checkin_time: startTime,
        checkout_time: endTime,
      });
    }
  }, [startDate, endDate, car]);

  useEffect(() => {
    const isDisabled =
      numberOfDays === 0 ||
      !Boolean(time.start) ||
      !Boolean(time.end) ||
      value === null;
    setIsBookBtnDisabled(isDisabled);
  }, [time, numberOfDays, status, value]);

  const bookNow = async () => {
    const start = moment(
      dates.start + ' ' + (time.start || '10:00 AM'),
      'YYYY-MM-DD h:mm a',
    ).format('YYYY-MM-DDTHH:mm:ss');
    const end = moment(
      dates.end + ' ' + (time.end || '10:00 AM'),
      'YYYY-MM-DD h:mm a',
    ).format('YYYY-MM-DDTHH:mm:ss');
    console.log(start, end);

    if (!user) {
      logoutFirebase();
      localStorage.removeItem('user');
      setUser(null);
      history.push({
        pathname: '/login',
        search: `?rtn_url=/car_detail/${id}&start=${start}&end=${end}`,
      });
      return;
    }
    if (!dbUser?.phoneNumber) {
      setOpen(true);
      message.warn('Please add your phone number before booking!');
    } else {
      setStatus('pending');
      let res = await reserveCar({
        start: start,
        end: end,
        insurancePerDay: Number(insuranceFee),
        pricePerDay: car?.pricePerDay,
        name: car?.name,
        id: car?.id,
        pickUpAndReturnLocation: car?.pickUpAndReturnLocation,
        image: car?.images[0],
        securityDeposit: car?.securityDeposit,
        userName: user?.displayName,
        paymentType: paymentType,
      });
      if (
        res?.id &&
        paymentType === 'online' &&
        stripe &&
        stripe.redirectToCheckout
      ) {
        stripe.redirectToCheckout({ sessionId: res.id });
      } else if (!res?.message && paymentType === 'direct') {
        history.push(`/car_reservation/success/done`);
      } else {
        message.error(
          res?.message ||
            'Something went wrong, please refresh the page and try again!',
        );
      }
      setStatus('resolved');
    }
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  function getDisabledDates() {
    let disabled = [];
    let splitStart = [];
    let splitEnd = [];

    reservations?.forEach((reservation) => {
      const startDate = moment(reservation?.start);
      const endDate = moment(reservation?.end);

      let currentDate = moment(startDate);
      while (currentDate.isSameOrBefore(endDate, 'date')) {
        if (currentDate.isSame(startDate)) {
          let midnight = currentDate.clone().startOf('day');

          const isHourDifferenceThree = currentDate.diff(midnight, 'hours') > 3;
          if (!isHourDifferenceThree) {
            disabled.push(currentDate.format('YYYY-MM-DD'));
          } else {
            splitEnd.push(reservation?.start);
          }
        } else if (endDate.isSame(currentDate, 'day')) {
          let midnight = currentDate.clone().add(1, 'day').startOf('day');

          const isHourDifferenceThree = midnight.diff(endDate, 'hours') > 3;
          if (!isHourDifferenceThree) {
            disabled.push(currentDate.format('YYYY-MM-DD'));
          } else {
            splitStart.push(reservation?.end);
          }
        } else {
          disabled.push(currentDate.format('YYYY-MM-DD'));
        }
        currentDate = currentDate.add(1, 'day');
      }
    });

    disabledDates?.forEach((date) => {
      const startDate = moment(date.start);
      const endDate = moment(date.end);

      let currentDate = moment(startDate);
      while (currentDate <= endDate) {
        disabled.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'day');
      }
    });

    const now = moment();
    let midnight = now.clone().add(1, 'day').startOf('day');
    if (midnight.diff(now, 'hours') < 3) {
      disabled.push(now.format('YYYY-MM-DD'));
    }

    return [disabled, splitEnd, splitStart];
  }

  const handleChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    if (id === 'checkin') {
      setTime((prev) => ({ ...prev, start: value }));
      setNumberOfDays(
        dayDifference(
          moment(
            moment(range?.range[0]).format('YYYY-MM-DD') +
              ' ' +
              (value || '10:00 AM'),
            'YYYY-MM-DD h:mm a',
          ).format('YYYY-MM-DDTHH:mm:ss'),
          moment(
            moment(range?.range[1]).format('YYYY-MM-DD') +
              ' ' +
              (time.end || '10:00 AM'),
            'YYYY-MM-DD h:mm a',
          ).format('YYYY-MM-DDTHH:mm:ss'),
        ),
      );
    } else {
      setTime((prev) => ({ ...prev, end: value }));
      setNumberOfDays(
        dayDifference(
          moment(
            moment(range?.range[0]).format('YYYY-MM-DD') +
              ' ' +
              (time.start || '10:00 AM'),
            'YYYY-MM-DD h:mm a',
          ).format('YYYY-MM-DDTHH:mm:ss'),
          moment(
            moment(range?.range[1]).format('YYYY-MM-DD') +
              ' ' +
              (value || '10:00 AM'),
            'YYYY-MM-DD h:mm a',
          ).format('YYYY-MM-DDTHH:mm:ss'),
        ),
      );
    }
  };

  const handleDateRangeChange = (value) => {
    const [start, end] = value;
    const dayDiff = dayDifference(
      moment(
        moment(start).format('YYYY-MM-DD') + ' ' + (time.start || '10:00 AM'),
        'YYYY-MM-DD h:mm a',
      ).format('YYYY-MM-DDTHH:mm:ss'),
      moment(
        moment(end).format('YYYY-MM-DD') + ' ' + (time.end || '10:00 AM'),
        'YYYY-MM-DD h:mm a',
      ).format('YYYY-MM-DDTHH:mm:ss'),
    );
    if (start && end && dayDiff >= 3) {
      setRange({ range: value });
      setIsMinDateSelected(true);
    } else if (start && end && dayDiff < 3) {
      setRange(undefined);
      setDates({
        start: null,
        end: null,
      });
      setNumberOfDays(0);
      // message.error('Please select a minimum of three days.');
      setIsMinDateSelected(false);
    }
  };

  function dayDifference(date1, date2) {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    let diff = Math.abs(d1 - d2);
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if (hours >= 1) {
      days++;
    }
    return days;
  }

  const onPaymentChange = (e) => {
    setPaymentType(e.target.value);
  };

  return (
    <>
      <section className="room-details">
        <div className="container">
          <div className="row pt-10 lg:pt-0">
            <div className="col-lg-8 relative bg-gray-100 rounded-lg lg:shadow">
              <div className="flex justify-between items-baseline mb-2">
                <div className="inline-block text-2xl lg:text-6xl leading-6">
                  <h2>{car?.name}</h2>
                </div>
                <div className="inline-block text-lg lg:text-3xl font-bold text-primary-color">
                  ${car?.pricePerDay}
                  <span className="text-sm lg:text-md">/day</span>
                </div>
              </div>
              <Carousel autoplay afterChange={() => {}}>
                {car?.images?.map((image) => {
                  return (
                    <div key={image}>
                      <div>
                        <img style={{ width: '100%' }} alt="car" src={image} />
                      </div>
                    </div>
                  );
                })}
              </Carousel>
              <p className="p-1 mt-2 text-sm text-justify">
                {car?.description}
              </p>
            </div>
            <div className="col-lg-4 pl-4">
              <div className="room-booking-form rounded-lg shadow">
                <div className="mb-4">
                  <div className="flex flex-col ">
                    <p className="text-sm text-gray-700 font-semibold mb-2">
                      Pickup Location
                    </p>
                    <a
                      href={`https://maps.google.com/?q=${car?.pickUpAndReturnLocation}`}
                      target="_blank"
                      rel="noreferrer"
                      className="flex text-blue-500"
                    >
                      <div className="flex justify-center items-center mr-2 text-blue-500">
                        <SlLocationPin className="text-xl text-blue-500" />
                      </div>
                      <p className="text-sm">{car?.pickUpAndReturnLocation}</p>
                    </a>
                  </div>
                  <Divider className="my-2" />
                  <span className="text-gray-400 mb-2 text-justify pl-2 flex">
                    <FiInfo size="25px" className="text-gray-500 pb-2" />
                    <p className="text-xs">
                      <i>
                        Kindly be aware that the booking duration must span at
                        least three(3) days.
                      </i>
                    </p>
                  </span>

                  <Form form={form}>
                    <p className=" text-gray-800 text-xs pl-2">
                      <span className="text-red-600">*</span> Dates:
                    </p>
                    <Form.Item
                      name="dates_rainbow"
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please Select check-in and checkout dates!',
                        },
                      ]}
                    >
                      {/* <Application
                        theme={theme}
                        className="rainbow-p-vertical_xx-large rainbow-align-content_center"
                      > */}
                      <DatePicker
                        id="datePicker-15"
                        placeholder="Select range of dates"
                        selectionType="range"
                        onChange={handleDateRangeChange}
                        className="w-full"
                        disabledDays={disabled}
                        minDate={new Date()}
                        variant={isMobile ? 'single' : 'double'}
                        icon={
                          <FiCalendar className="font-bold text-gray-700" />
                        }
                      />
                      {/* </Application> */}
                    </Form.Item>
                    {!isMinDateSelected && (
                      <p className="text-xs text-red-400 text-left pl-2">
                        *Please select a minimum of three days!
                      </p>
                    )}
                    <div className="flex gap-1 w-full">
                      <span className="flex-1">
                        <p className="text-gray-800 text-xs mt-3 pl-2">
                          <span className="text-red-600">*</span>Check-in Time:
                        </p>
                        <Form.Item
                          name="checkin_time"
                          rules={[
                            {
                              required: true,
                              message: 'Please Select check-in time!',
                            },
                          ]}
                        >
                          <div className="select-container">
                            <FiClock className="time-icon" />
                            <select
                              value={time.start}
                              onChange={handleChange}
                              id="checkin"
                            >
                              {options.map((option, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={option.value}
                                    className="text-bold"
                                  >
                                    {option.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </Form.Item>
                      </span>
                      <span className="flex-1">
                        <p className="text-gray-800 text-xs mt-3 pl-2">
                          <span className="text-red-600">*</span>CheckOut Time:
                        </p>
                        <Form.Item
                          name="checkout_time"
                          rules={[
                            {
                              required: true,
                              message: 'Please Select checkout time!',
                            },
                          ]}
                        >
                          <div className="select-container">
                            <FiClock className="time-icon" />
                            <select
                              value={time.end}
                              onChange={handleChange}
                              id="checkout"
                            >
                              {checkoutOptions.map((option, index) => {
                                return (
                                  <option key={index} value={option.value}>
                                    {option.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </Form.Item>
                      </span>
                    </div>
                    <Form.Item
                      name="insuranceByRenter"
                      rules={[
                        {
                          required: true,
                          message: 'Please choose an option!',
                        },
                      ]}
                    >
                      <div className="flex ml-2">
                        <p className="text-sm text-gray-800 font-semibold flex-1">
                          Insurance will be provided by Renter:
                        </p>
                        <Radio.Group
                          onChange={onChange}
                          value={value}
                          className="text-primary-color"
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </div>
                    </Form.Item>
                  </Form>

                  {!isBookBtnDisabled && Object.keys(car).length !== 0 && (
                    <div className="">
                      <div className="flex justify-between text-sm text-gray-800 mt-2">
                        <div>
                          <span className="inline-block ml-2 underline">
                            ${car?.pricePerDay?.toFixed(2)} x {numberOfDays}{' '}
                            Day(s)
                          </span>
                        </div>
                        <div className="font-semibold text-right mr-3">
                          ${(car?.pricePerDay * numberOfDays)?.toFixed(2)}
                        </div>
                      </div>
                      <div className="flex justify-between text-sm  text-gray-800 mt-3">
                        <div className="underline">
                          <span className="inline-block ml-2 underline">
                            Insurance ${insuranceFee} x {numberOfDays} Day(s)
                          </span>
                        </div>
                        <div className="font-semibold text-right mr-3">
                          ${(insuranceFee * numberOfDays)?.toFixed(2)}
                        </div>
                      </div>
                      <div className="flex justify-between text-sm text-gray-800 mt-3">
                        <div>
                          <span className="inline-block ml-2 underline">
                            Tax
                          </span>
                        </div>
                        <div className="font-semibold text-right mr-3">
                          ${(initialDailyTotal * 0.1346)?.toFixed(2)}
                        </div>
                      </div>
                      <div className="flex justify-between text-sm text-gray-800 mt-3">
                        <div className="flex align-items-center gap-1">
                          <span className="inline-block ml-2 underline">
                            Security Purposes
                          </span>
                          <Tooltip
                            color="#ffff"
                            title={
                              <p className="text-gray-800">{promptText}</p>
                            }
                          >
                            <FiInfo
                              size="17px"
                              strokeWidth="1.5px"
                              className="text-gray-600 hover:text-gray-800"
                            />
                          </Tooltip>
                        </div>
                        <div className="font-semibold text-right mr-3">
                          ${car?.securityDeposit}
                        </div>
                      </div>
                      <div className="flex justify-between text-sm text-gray-800 mt-3">
                        <div>
                          <span className="inline-block ml-2 underline">
                            {200 * numberOfDays} Total Miles
                          </span>
                        </div>
                        <div>
                          <div className="font-semibold text-right mr-3">
                            Free
                          </div>
                        </div>
                      </div>
                      <div className="border border-primary-color rounded-lg p-2 pr-0 mt-4">
                        <div className=" flex justify-between font-semibold text-sm text-gray-900 ">
                          <div>
                            <span className="inline-block mr-2 text-sm">
                              Total
                            </span>
                          </div>
                          <div>
                            <span className="inline-block font-semibold text-gray-900">
                              $
                              {(
                                parseFloat(initialDailyTotal) +
                                parseFloat(
                                  (initialDailyTotal * 0.1346)?.toFixed(2),
                                ) +
                                car?.securityDeposit
                              ).toFixed(2)}
                            </span>
                          </div>
                        </div>
                        <Divider className="my-2" />
                        <div className="flex md:gap-4 justify-between">
                          <p className="text-sm text-gray-800 font-semibold mb-2 md:mb-0 md:flex-1">
                            Payment Options:
                          </p>
                          <div className="flex flex-col md:flex-row">
                            <Radio.Group
                              onChange={onPaymentChange}
                              value={paymentType}
                              className="flex flex-col md:flex-row"
                            >
                              <Radio value="online" className="mb-2 md:mb-0">
                                Pay Online
                              </Radio>
                              <Radio value="direct" className="mb-2 md:mb-0">
                                Pay Direct (zelle, cash)
                              </Radio>
                            </Radio.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex justify-between text-xl text-bold text-gray-800 mt-4">
                    <Button
                      disabled={isBookBtnDisabled || status === 'pending'}
                      onClick={bookNow}
                      className={`main-btn rounded-lg ${
                        !isBookBtnDisabled ? 'btn-filled' : ''
                      }`}
                    >
                      {status === 'pending' ? (
                        <div className="flex justify-center items-center w-full">
                          <Spin size="large" col />
                        </div>
                      ) : (
                        'Book Now'
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddPhNumModal
          open={open}
          setOpen={setOpen}
          signedIn={true}
          dates={dates}
          car={car}
          stripe={stripe}
          setStatus={setStatus}
        />
      </section>
    </>
  );
};

export default SearchDetail;
