import React from 'react';
import CarRevSuccess from '../components/CarRevSuccess';

export default function CarReservationSuccess() {
  return (
    <section className="h-full">
      <CarRevSuccess />
    </section>
  );
}
