import React, { useContext } from 'react';
import UserContext from '../context/UserContext';
import { Avatar, Button, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const EditProfileModal = ({ open, setOpen }) => {
  const { user } = useContext(UserContext);

  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        title="Profile Details"
        visible={open}
        footer={[<Button onClick={handleCancel}>Close</Button>]}
        className="w-fit"
        onCancel={handleCancel}
      >
        <div className="flex gap-2">
          <Avatar icon={<UserOutlined />} size="medium" />
          <div>
            <p className="text-lg font-semibold">{user.displayName}</p>
            <p className="text-gray-600">{user.email}</p>
            <p className="text-gray-600">{user.phoneNumber}</p>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default EditProfileModal;
