import React, { Fragment, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import Home from './pages/Home';
import AdminGuard from '../shared/auth/AdminGuard';
import { useFormik, FormikProvider } from 'formik';
import Users from './pages/Users';
import Reservations from './pages/Reservations';
import useScrollToTop from '../shared/hooks/useScrollToTop';
import ApproveModifyTripRequest from './pages/ApproveModifyTripRequest';

function Admin(props) {
  const formikStateAndHelpers = useFormik(props);
  useScrollToTop();
  return (
    <Fragment>
      <FormikProvider value={formikStateAndHelpers}>
        <Suspense fallback={<div></div>}>
          <div className="flex-1">
            <Switch>
              <AdminGuard exact path="/admin" component={Home} />
              <AdminGuard exact path="/admin/users" component={Users} />
              <AdminGuard
                exact
                path="/admin/reservations"
                component={Reservations}
              />
              <AdminGuard
                exact
                path="/admin/modify_trip"
                component={ApproveModifyTripRequest}
              />
            </Switch>
          </div>
        </Suspense>
      </FormikProvider>
    </Fragment>
  );
}

Admin.propTypes = {};

export default Admin;
