import React from 'react';
import { Tooltip } from 'antd';
import { FiInfo } from 'react-icons/fi';
import moment from 'moment';

const DetailedReceipt = ({ reservation, numberOfDays, initialDailyTotal }) => {
  const bool = moment(reservation.created).isAfter(moment('12/18/2023'));
  const promptText =
    'The security deposit serves as a financial assurance held by the lessor until the lessee returns the rental car. This amount is earmarked to cover unforeseen expenses, including but not limited to damages to the vehicle, toll charges, and parking fines. Any remaining balance from the initial deposit of $200 will be promptly refunded to the lessee upon the satisfactory conclusion of the rental period. This practice ensures a transparent and efficient resolution of any incidental charges incurred during the course of the lease agreement.';

  return (
    <div className="">
      <div className="flex justify-between text-sm text-gray-800 mt-2">
        <div>
          <span className="inline-block ml-2 underline">
            ${reservation?.pricePerDay?.toFixed(2)} x {numberOfDays} Day(s)
          </span>
        </div>
        <div>
          <span className="inline-block mr-3">
            ${(reservation?.pricePerDay * numberOfDays)?.toFixed(2)}
          </span>
        </div>
      </div>
      <div className="flex justify-between text-sm  text-gray-800 mt-3">
        <div className="underline">
          <span className="inline-block ml-2 underline">
            Insurance ${reservation?.insurancePerDay} x {numberOfDays} Day(s)
          </span>
        </div>
        <div>
          <span className="inline-block mr-3">
            ${(reservation?.insurancePerDay * numberOfDays)?.toFixed(2)}
          </span>
        </div>
      </div>
      <div className="flex justify-between text-sm text-gray-800 mt-3">
        <div>
          <span className="inline-block ml-2 underline">Tax</span>
        </div>
        <div>
          <span className="inline-block mr-3">${initialDailyTotal}</span>
        </div>
      </div>
      {bool && (
        <div className="flex justify-between text-sm text-gray-800 mt-3">
          <div className="flex align-items-center gap-1">
            <span className="inline-block ml-2 underline">
              Security Purposes
            </span>
            <Tooltip
              color="#ffff"
              title={<p className="text-gray-800">{promptText}</p>}
              placement="left"
            >
              <FiInfo
                size="17px"
                strokeWidth="1.5px"
                className="text-gray-600 hover:text-gray-800"
              />
            </Tooltip>
          </div>
          <div className="text-right mr-3">${reservation?.securityDeposit}</div>
        </div>
      )}
      <div className="flex justify-between text-sm text-gray-800 mt-3">
        <div>
          <span className="inline-block ml-2 underline">
            {200 * numberOfDays} Total Miles
          </span>
        </div>
        <div>
          <span className="inline-block mr-3 capitalize">Free</span>
        </div>
      </div>
      <div className="border border-primary-color rounded-lg p-2 pr-0 flex justify-between font-bold text-sm text-gray-900 mt-4">
        <div>
          <span className="inline-block mr-2 text-sm">Total</span>
        </div>
        <div>
          <span className="inline-block font-bold text-gray-900">
            ${reservation?.total}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DetailedReceipt;
