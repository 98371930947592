export default function getParams(params) {
  let p = '';
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      if (p) {
        p += `&${key}=${params[key]}`;
      } else {
        p += `${key}=${params[key]}`;
      }
    }
  });

  return p;
}
