import React from 'react';
import { FaMeetup } from 'react-icons/fa';
import { useHistory } from 'react-router';

import img1 from '../../assets/img/we-offer/01.jpg';
import img4 from '../../assets/img/we-offer/04.jpg';

const facilitiespost = [
  {
    img: img1,
    icon: 'flaticon-car',
    title: 'Car Rental | ክራይ ማኪና',
    text: 'Come rent our cars for long or short term.',
    link: 'search_results',
  },
  // {
  //   img: img4,
  //   icon: 'flaticon-hotel',
  //   title: 'Vacation Rental | ክራይ ገዛ',
  //   text: 'Find your perfect vacation getaway with just a click.',
  //   link: 'apartment_search_results',
  // },
];

const Facilities = () => {
  const history = useHistory();

  return (
    <section className="condos-overlay-sec">
      <div className="container-fluid">
        <div className="section-title text-center ">
          {/* <span className="title-tag"> facilities </span> */}
          <h2>What We Offer</h2>
          <h3>ኣገልግሎትና</h3>
        </div>
        <div className="row justify-center">
          {facilitiespost.map((item, i) => (
            <button
              key={i}
              onClick={() => {
                history.push(item.link);
              }}
              className="col-lg-4 col-md-5 p-2 facility_card"
            >
              <div
                className="condo-item hotel-intro shadow-sm hover:shadow-2xl "
                style={{ backgroundImage: 'url(' + item.img + ')' }}
              >
                <div className="title">
                  <div className="icon">
                    <i className={item.icon} />
                  </div>
                  <h4 className="text-white text-xl lg:text-2xl">
                    {item.title}
                  </h4>
                  <div className="display-on-hover">
                    <p className="text-white">{item.text}</p>
                  </div>
                </div>
              </div>
            </button>
          ))}
        </div>
        <div className="flex justify-center">
          <a
            href="https://calendly.com/mhtemnewo/mrara-car-rental-meeting"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center rounded-full border-2 border-primary-color hover:border-primary-hover-color bg-white schedule_button"
          >
            <span className="inline-block text-sm mr-2 schedule_button__text">
              Schedule a Meeting
            </span>
            <span className="p-1">
              <FaMeetup className="text-2xl text-primary-color" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Facilities;
