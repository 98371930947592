import React, { useState } from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { Link } from 'react-router-dom';
import { sendPasswordReset } from '../../db/firebase';

function PasswordResetRequestForm(props) {
  const [emailSent, setEmailSent] = useState(false);

  const [error, setError] = useState('');

  const onFinish = async ({ email }) => {
    setError('');
    const res = sendPasswordReset(email);
    if (res) {
      setEmailSent(true);
    } else {
      setError('Can not send email.');
    }
  };
  return (
    <div className="flex justify-center">
      <div className="flex-col lg:w-1/2 w-full items-center justify-center p-2 lg:p-8 pt-20 bg-white shadow-md rounded-3xl">
        <h1 className="w-full text-center text-4xl font-bold mt-4">
          Reset Password
        </h1>
        {emailSent && (
          <div className="my-2 max-w-sm mx-auto text-center">
            <Alert
              type="success"
              message="Email successfully sent. Check you inbox to reset your password."
            />
          </div>
        )}

        {!emailSent && (
          <Form
            name="password_reset_request"
            className=" max-w-xl p-8 flex-col mx-auto"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            {error && (
              <span className="inline-block w-full p-2 text-center py-1 mb-4 bg-red-300 text-red-900 font-bold rounded-sm">
                {error}
              </span>
            )}
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input
                type="email"
                prefix={<i className="fal fa-user mr-2 font-semibold" />}
                size="large"
                className="rounded-3xl"
                placeholder="Enter your email"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="rounded-3xl login-form-button w-full mb-4 bg-primary-color hover:bg-primary-hover-color hover:border-primary-hover-color focus:bg-primary-color-hover text-white font-semibold text-gray-700"
              >
                Submit
              </Button>
              <Link
                to="/login"
                className="text-md text-primary-color hover:text-primary-hover-color mx-1 font-semibold hover:underline"
              >
                Sign In
              </Link>{' '}
              or{' '}
              <Link
                to="/sign_up"
                className="text-md text-primary-color hover:text-primary-hover-color mx-1 font-semibold hover:underline"
              >
                Register Now
              </Link>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
}

export default PasswordResetRequestForm;
