import React from 'react';
import SearchDetail from '../components/SearchDetail';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);
function CarDetail(props) {
  return (
    <div>
      <section className="pb-8">
        <Elements stripe={stripePromise}>
          <SearchDetail />
        </Elements>
      </section>
    </div>
  );
}

CarDetail.propTypes = {};

export default CarDetail;
